import Api from "@/http/Api";
//部分校验规则
export class Validators{
    //身份证号校验
    public static isIdCard(idCard:string):boolean{
        if(idCard.length == 18){
            const id:Array<string> = idCard.split('')
            const factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2 ];
            const parity = [1, 0, 'X', 9, 8, 7, 6, 5, 4 ,3, 2];
            let sum:any = 0;
            let ai:any = 0;
            let wi:any = 0;
            for(let i = 0; i < 17; i++){
                ai = id[i];
                wi = factor[i];
                sum += ai * wi;
            }
            if(parity[sum%11] == id[17].toUpperCase()){
                return true;
            }
        }

        return false
    }
    //手机号，固定电话校验 phone
    public static isPhone(number:string):boolean {
        const phone = /^((\d{7,8})|(0\d{2,3})(\d{7,8})|(\d{3,4})-(\d{7,8})|(\d{3,4})-(\d{7,8})-(\d{1,5})|(\d{7,8})-(\d{1,5}))$/;
        const mobile = /^1[3|4|5|7|6|8|9][0-9]{9}$/;
        if(phone.test(number)||mobile.test(number)) {
            return true;
        }

        return false;
    }

    //单独手机号
    public static isMobile(mobile:string):boolean{
        const regExp = /^1[3|4|5|7|6|8｜9][0-9]{9}$/;
        if(regExp.test(mobile)){
            return true;
        }
        return false;
    }

    //单独电话号
    public static isTel(tel:string):boolean{
        const regExp = /^((\d{7,8})|(\d{4}|\d{3})(\d{7,8})|(\d{4}|\d{3})-(\d{7,8})|(\d{4}|\d{3})-(\d{7,8})-(\d{4}|\d{3}|\d{2}|\d{1})|(\d{7,8})-(\d{4}|\d{3}|\d{2}|\d{1}))$/;
        if(regExp.test(tel)){
            return true;
        }

        return false;
    }

    //邮箱校验
    public static isEmail(value: string): boolean {
        // eslint-disable-next-line
        const regExp = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;
        if (regExp.test(value.toLocaleLowerCase())) {
            return true;
        } else {
            return false;
        }
    }

    //统一社会信用代码校验
    public static async isSocialCreditCode(code:string):Promise<boolean>{
        const creditCode  = {
            "format_type":7,
            "data":code
        }
        let result = false;
        await Api.formatCheck(creditCode).then((res) =>{
            if(res && res.status){
                result = res.data.value;
            }
        })

        return result;
    }

    //不含数字校验
    public static withoutNum(value: string): boolean {
        // eslint-disable-next-line
        const regExp = /^[^0-9]*$/;
        if (regExp.test(value.toLocaleLowerCase())) {
            return true;
        } else {
            return false;
        }
    }
    //不含中文校验
    public static withoutCH(value: string): boolean {
        // eslint-disable-next-line
        const regExp = /^[^\u4e00-\u9fa5]+$/;
        if (regExp.test(value.toLocaleLowerCase())) {
            return true;
        } else {
            return false;
        }
    }
}

//规则类型
const enum RegularTypes{
    mobile = 'mobile',
    phone = 'phone',
    tel = 'tel',
    social_credit_code = 'social_credit_code',
    id_card_number = 'id_card_number',
    email = 'email',
    without_num = 'withoutNum',
    withoutCH = 'withoutCH'
}

/// <summary>
///  格式类别 格式校验代码对照 
/// </summary>
const enum Format_Type{
    /// <summary>
    /// 手机号
    /// </summary>
    mobile = 0,
    /// <summary>
    /// 电话（包含手机和固话）
    /// </summary>
    phone = 1,
    /// <summary>
    /// 邮箱
    /// </summary>
    email = 2,
    /// <summary>
    /// 固话
    /// </summary>
    tel = 3,
    /// <summary>
    /// 单位组织机构代码（或工商注册号）
    /// </summary>
    register_number = 4,
    /// <summary>
    /// 密码
    /// </summary>
    password = 5,
    /// <summary>
    /// 连续字符检测
    /// </summary>
    continuous_character = 6,
    /// <summary>
    /// 社会统一信用代码
    /// </summary>
    social_credit_code = 7,
    /// <summary>
    /// 身份证号码
    /// </summary>
    id_card_number = 8
}

//规则字典 根据字符获取方法
const Regular = new Map<string,any>();
Regular.set(RegularTypes.id_card_number,Validators.isIdCard);
Regular.set(RegularTypes.phone,Validators.isPhone);
Regular.set(RegularTypes.email,Validators.isEmail);
Regular.set(RegularTypes.mobile,Validators.isMobile);
Regular.set(RegularTypes.tel,Validators.isTel);
Regular.set(RegularTypes.social_credit_code,Validators.isSocialCreditCode);
Regular.set(RegularTypes.without_num,Validators.withoutNum);
Regular.set(RegularTypes.withoutCH,Validators.withoutCH);
export{
    Regular
} 

