<template>
    <div class="box">
        <div class="title">申请{{ recordTitle }}</div>
        <div class="rescind-box">
            <div class="lable"><span>{{ recordTitle }}原因：</span><span></span></div>
            <div class="content">
                <el-input v-model="reason" :rows="7" :placeholder="`请输入${recordTitle}原因`" type="textarea"></el-input>
            </div>
        </div>
        <div class="rescind-box">
            <div class="lable"><span>上传{{ recordTitle }}函：</span><span>(加盖公司公章)</span></div>
            <div class="content">
                <el-button type="primary" @click="downloadRescindLetter()">下载{{ recordTitle }}函模板</el-button>
                <el-upload v-if="attachments.length<4" :action="uploadUrl" class="upload-demo" :before-upload="uploadFile" :limit='3' :show-file-list="false">
                    <el-button  type="primary">上传图片</el-button>
                </el-upload>
                <el-button type="danger" @click="clearImg()">清空图片</el-button>
                <!-- <el-input v-model="reason" :rows="5" type="textarea"></el-input> -->
            </div>
        </div>
        <div class="image-list">
            <el-image v-for="image of attachments" :key="image" :src="image" :fit="fit" :preview-src-list="attachments" :initial-index="0"/>
        </div>
        <div class="btn-wrap">
            <el-button type="primary" @click="rescind()">确定{{ recordTitle }}</el-button>
        </div>
    </div>
</template>
<script lang="ts">
import { defineComponent, reactive, ref, computed } from "vue";
import { ElMessage } from "element-plus";
import Constants from "@/http/Constant";
import Api from "@/http/Api";
import { useRoute, useRouter } from "vue-router";
import { StorgeUtils } from "@/utils/StorageUtils";

export default defineComponent({
  name: "rescindSign",
  setup() {
    const router = useRouter();
    const route = useRoute();
    const reason = ref<string>("");
    const recordid = ref<string>("");
    const recordtype = ref<string>("");
    const attachments = reactive(new Array<string>());
    const fit = ref<string>('contain');
    const uploadUrl = `${Constants.ApiUrl}/signup/v3/uploadimage`;
    const rescindLetterTemplate = ref<string>("");
    const revokeFileTemplate = ref<string>("");  
    //提交解约参数
    const params = reactive({
        recordid: "",
        reason: "",
        attachments: Array<string>(),
    });
    if(route.query.recordid){
        let id:any = route.query.recordid;
        recordid.value = id
    }
    if(route.query.recordtype){
        recordtype.value = route.query.recordtype as string
    }
   

    //获取签约配置
    Api.getSignSetting().then((res) => {
        if (res.status) {
            rescindLetterTemplate.value = res.data.cancel_file_url;
            revokeFileTemplate.value = res.data.revoke_file_url
        }
    });

    //获取解约状态
    Api.getRescindSignState(recordid.value).then((res) =>{
        if(res.status){
            console.log(res)
            reason.value = res.data.reason
            res.data.attachments.forEach(v=>{
                attachments.push(v)
            })
        }
    })
    
    const rescind = () =>{
        if(!reason.value){
            ElMessage({
              message: `请填写${ recordTitle.value }原因！`,
              type: "warning",
              offset: 50,
            });
            return;
        }
        if(!attachments.length){
            ElMessage({
              message: `请上传${ recordTitle.value }函`,
              type: "warning",
              offset: 50,
            });
            return;
        }
        params.reason = reason.value;
        params.recordid = recordid.value;
        params.attachments = attachments;
        console.log(params);
        let fnKey = 'cancelSign'
        if(recordtype.value == 'revoke'){
            fnKey = 'revokeSign'
        }
        Api[fnKey](params).then((res) =>{
            console.log(res);
            if(res.status){
                ElMessage({
                    message: `${ recordTitle.value }申请已提交，请等待审核！`,
                    type: "success",
                    offset: 50,
                });
                setTimeout(() => {
                    window['wx'].miniProgram.navigateBack({delta:2})
                    window.parent.postMessage({ page: 'rescindSign' }, '*')
                }, 2000);
            }

        })
    }

    const uploadFile = (file) =>{
        if (!file.type.includes('image')) {
            ElMessage.error('请上传jpg或png图片!')
            return false
        }
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = (e) =>{
            let imageData = reader.result;
            console.log(typeof imageData);
            if(imageData){
                imageData = replaceStr(imageData);
                let data = {value:imageData};
                Api.uploadImage(data).then((res) => {
                    if(res.status){
                        attachments.push(res.data.value)
                        console.log(attachments);
                    }
                })
            }
        }
        return false;
    }

    const replaceStr = (imageData:any) =>{
        let result = imageData;
        result = result.replace(/^(data:image)\/jpeg;base64,/g, '')
        result = result.replace(/^(data:image)\/png;base64,/g, '')
        return result;
    }

    const wxjump = (url?: string) => {
      let params = {
        url: url,
      }
      console.log(params, 'wxjump')
      window['wx'].miniProgram.navigateTo({
        "url": `/pages/subpackage/activitySub/skipDownload/skipDownload?params=${encodeURIComponent(JSON.stringify(params))}`,
      })
    }

    const downloadRescindLetter = () =>{
      let userType = StorgeUtils.get("usertype")
      if (userType !== 'wx') {
        if(recordtype.value == 'revoke'){
            window.open(revokeFileTemplate.value)
        }else{
            window.open(rescindLetterTemplate.value)
        }
      } else {
        if(recordtype.value == 'revoke'){
            wxjump(revokeFileTemplate.value)
        }else{
            wxjump(rescindLetterTemplate.value)
        }
      }
    }

    const clearImg = () =>{
       let length = attachments.length;
       attachments.splice(0,length);
    }
    const recordTitle = computed(()=>{
        let recordTitle = '解约'
        if(recordtype.value == 'revoke'){
            recordTitle = '撤回'
        }
        return recordTitle
    })
    return { reason, attachments,rescind,fit,uploadUrl,uploadFile,downloadRescindLetter,clearImg,recordTitle };
  },
});
</script>
<style scoped>
.title{
    height: 30px;
    font-size: 20px;
    color: #5e4fff;
    font-weight: 700;
    text-align: center;
    line-height: 30px;
    margin-bottom: 40px;
}
.rescind-box{
    width: 600px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0 auto;
    margin-bottom: 15px;

}
.rescind-box .lable{
    width: 120px;
    text-align: right;
    display: flex;
    flex-direction: column;
}
.rescind-box .lable >span:first-child{
    font-size: 16px;
    color: #b1b4be;
}
.rescind-box .lable >span:last-child{
    font-size: 12px;
    color: #b1b4be;
    text-align: center;
}
.rescind-box .content{
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.image-list{
    width: 600px;
    margin: 0 auto;
}
.image-list .el-image{
    width: 100px;
    height: 100px;
    margin-right: 10px;
}
.btn-wrap {
    height: 60px;
    margin: 20px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}
@media screen and (max-width:750px) {
    .title{
        font-size: 16px;
    }
    .rescind-box{
        box-sizing: border-box;
        width: 100%;
        padding: 0 5%;
        flex-direction: column;
    }
    .rescind-box .lable{
        width: 100%;
        text-align: left;
        flex-direction: row;
    }
    .rescind-box .content{
        width: 100%;
        padding-top: 10px;
    }
    .content .el-button{
        height: 24px;
        font-size: 12px;
        padding: 8px 10px;
    }
}
</style>

