import { FormItem } from "@/form/dataform";
import { Regular } from "@/utils/Validators";
import { ElMessage } from 'element-plus';
import { useStore } from "vuex";
import { key, store } from '../state/store';
import { nextTick } from "vue";


export class FormValidators{
    private store = useStore(key);
    //统一提示语
    private static prompt(item:FormItem,type:string):string{
        const tips = type == 'regular' ? `${item.name}格式不正确`: `${TipsString[item.control_type]}${item.name}`;
        return tips;
    }
    //表单校验方法
    private static async checkValidators(value:any,item:FormItem):Promise<boolean>{
        store.commit("setErrorFileList", []);
        //判断是否可为空
        if(item.required && (typeof(value) == "undefined" || value==null || typeof(value)=='number' && isNaN(value)||value==='')){
            const tips = this.prompt(item,'required');
            store.commit("setErrorFileList", [item.field]);
            ElMessage({
                message:tips,
                type:'warning',
                offset:50
            })
            return false;
        }
        if(item.field == 'protocol_photos'&&item.required){
            console.log(value.length);
            if((value||[]).length <= 0){
                const tips = this.prompt(item,'required');
                console.log(tips);
                ElMessage({
                    message:tips,
                    type:'warning',
                    offset:50
                })

                return false;
            }
            
        }
        //规则校验
        if(item.regular && value){
            const check = Regular.get(item.regular)
            const result = await check(value)
            if(!result){
                const tips = this.prompt(item,'regular');
                store.commit("setErrorFileList", [item.field]);
                ElMessage.error(tips);
                return false
            }
        }

        return true
    }
    static async checkForm(rules:Array<FormItem>,results:Map<string,any>,skipField?:Array<string>):Promise<boolean>{
        const checkMobileRes = this.checkMobile(results,rules)
            if(!checkMobileRes){
                return false;
            }
        //遍历校验
        for(const item of rules){
            if(item.is_disable){
                continue;
            }
            if(!store.state.isDirectional && item.field == 'orientation_department_name'){
                continue;
            }
            if(!store.state.isTrouble && item.field == 'filing_card_poor_family'){
                continue;
            }
            if(skipField&&skipField.includes(item.field)){
                continue;
            }
            const result = await this.checkValidators(results.get(item.field),item);
            if(!result){
                nextTick(()=>{
                    (window as any).document.querySelector('.el-form-item.is-error')?.scrollIntoView(true)
                })
                return false;
            }
        }
        return true;
    }
    // 电话校验重复存值
    static checkMobileData = {
        home_phone:'',
        mobile:''
    }
    // 字段编辑权限
    static fildDisable = {
        home_phone:false,
        mobile:false,
        department_phone:false,
        department_mobile:false,
    }
    // 电话校验重复
    private static checkMobile(results:Map<string,any>,rules:Array<FormItem>):boolean{
        this.checkMobileData.home_phone = results.get('home_phone') || this.checkMobileData.home_phone
        this.checkMobileData.mobile = results.get('mobile') || this.checkMobileData.mobile
        this.fildDisable.mobile = (rules.filter(v=>v.field=='mobile')[0]||{is_disable:this.fildDisable.mobile}).is_disable || false
        this.fildDisable.home_phone = (rules.filter(v=>v.field=='home_phone')[0]||{is_disable:this.fildDisable.home_phone}).is_disable || false
        this.fildDisable.department_phone = (rules.filter(v=>v.field=='department_phone')[0]||{is_disable:this.fildDisable.department_phone}).is_disable || false
        this.fildDisable.department_mobile = (rules.filter(v=>v.field=='department_mobile')[0]||{is_disable:this.fildDisable.department_mobile}).is_disable || false
        const valO = <any>{};
        ['employment_type','department_phone','department_mobile'].forEach(v=>{
            valO[v] = results.get(v)
        })
        const {employment_type,department_phone,department_mobile} = valO
        const {home_phone,mobile} =  this.checkMobileData
        const fildDisable:any={}
        for(const item of rules){
            fildDisable[item.field] = item.is_disable
        }
        if(['05','08','09','11','13','14','28','29','30','31','32','33','34','35','36'].includes(employment_type)){
            return true
        }
        // if(mobile && home_phone && mobile == home_phone && !(this.fildDisable.mobile && this.fildDisable.department_phone)){
        //     ElMessage({
        //         message:'家庭电话不可与联系方式相同',
        //         type:'warning',
        //         offset:50
        //     })
        //     return false
        // }
        if(mobile && department_phone && mobile == department_phone && !(this.fildDisable.mobile && this.fildDisable.department_phone)){
            ElMessage({
                message:'单位办公电话不可与手机号码相同',
                type:'warning',
                offset:50
            })
            return false
        }
        if(mobile && department_mobile && mobile == department_mobile && !(this.fildDisable.mobile && this.fildDisable.department_mobile)){
            ElMessage({
                message:'联系人手机号不可与手机号码相同',
                type:'warning',
                offset:50
            })
            return false
        }
        if(home_phone && department_phone && home_phone == department_phone && !(this.fildDisable.home_phone && this.fildDisable.department_phone)){
            ElMessage({
                message:'单位办公电话不可与家庭电话相同',
                type:'warning',
                offset:50
            })
            return false
        }
        if(home_phone && department_mobile && home_phone == department_mobile && !(this.fildDisable.home_phone && this.fildDisable.department_mobile)){
            ElMessage({
                message:'联系人手机号不可与家庭电话相同',
                type:'warning',
                offset:50
            })
            return false
        }
        // if(department_phone && department_mobile && department_phone == department_mobile ){
        //     ElMessage({
        //         message:'联系人手机号不可与单位办公电话相同',
        //         type:'warning',
        //         offset:50
        //     })
        //     return false
        // }
        return true
    }
}
//不同类型提示语前缀
enum TipsString{
    dropdownCode = '请选择',
    dropdown = '请选择',
    dropdownv2 = '请选择',
    textbox = '请输入',
    areaselect = '请选择',
    imageupload = '请上传',
    number = '请输入',
    textarea = '请输入',
    select = '请选择',
    datetime = '请选择'
}
