<template>
  <router-view />
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";
import { StorgeUtils } from "@/utils/StorageUtils";
import Constants from "@/http/Constant";
import { dict } from "@/form/dictProvider";
export default defineComponent({
  name: "App",
  setup() {
    const receiveMessage = () => {
      window.addEventListener('message', (e) => {
        console.log(e)
        if (e.origin == Constants.origin || e.origin == 'http://127.0.0.1:4200' || e.origin == Constants.originCollege || e.origin == "http://127.0.0.1:5500") {
          for (let key in e.data) {
            StorgeUtils.set(key, e.data[key])
          }
        }
      })
    }
    onMounted(() => {
      receiveMessage();
      new dict().PullDictVersion()
    })

    return { receiveMessage, location };
  },
});
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  padding-top: 10px;
  background: #ffffff;
  overflow: hidden;
}

.el-scrollbar__bar {
  opacity: 0 !important;
}
</style>
