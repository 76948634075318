import { signMode } from '@/form/dataform';
const online = 'signType1.png';
const offline = 'signType2.png';
const paper = 'signType3.png';
export class JosnData {
    //学制
    public static schoolLength = JSON.stringify(
        [
            { text: '0.5', code: 0.5 },
            { text: '1', code: 1 },
            { text: '1.5', code: 1.5 },
            { text: '2', code: 2 },
            { text: '2.5', code: 2.5 },
            { text: '3', code: 3 },
            { text: '3.5', code: 3.5 },
            { text: '4', code: 4 },
            { text: '4.5', code: 4.5 },
            { text: '5', code: 5 },
            { text: '5.5', code: 5.5 },
            { text: '6', code: 6 },
            { text: '6.5', code: 6.5 },
            { text: '7', code: 7 },
            { text: '7.5', code: 7.5 },
            { text: '8', code: 8 },
        ]
    )
    // 签约方式
    public static signModeOptions = JSON.stringify([
        { text: '线上签约', code: 1 },
        { text: '协议书打印签约', code: 2 },
        { text: '已签协议书备案', code: 0 },
    ])
    //签约配置
    public static signModeSetting = JSON.stringify([
        {
            signMode: signMode.online,
            signText: '线上签约',
            signImg: require(`@/assets/${online}`),
            remark: '用人单位参与线上签约',
            signStr: 'online',
        },
        {
            signMode: signMode.offline,
            signText: '协议书打印签约',
            signImg: require(`@/assets/${offline}`),
            remark: '*本人独立完成，线上生成就业协议书并上传',
            signStr: 'offline',
        },
        {
            signMode: signMode.paper,
            signText: '已签协议书备案',
            signImg: require(`@/assets/${paper}`),
            remark: '*本人独立完成，需上传纸质协议书照片',
            signStr: 'paper',
        },
    ])
}