import { InjectionKey } from "vue";
import { createStore, Store } from "vuex";
import { majoritem } from "@/form/dictProvider";

export interface State{
    education:string,
    training_mode:string,
    major_list:Array<majoritem>,
    isDirectional:boolean,
    isTrouble:boolean,
    employment_type:string,
    employment_status:string,
    datePickO:object,
    departmentRegionShow:boolean,
    fieldRemark:object,
    signMode:number,
    signModeOptions:any[],
    autoGenerateEmploymenNumber:number,
    errorFileList:string[],
    studyAbroadCollege:any
}

export const key :InjectionKey<Store<State>> = Symbol();

export const store = createStore<State>({
    state:{
        education:'',//学历
        training_mode:'',//培养方式
        major_list:[],//专业列表
        isDirectional:false,//培养方式是否为定向或者委培
        isTrouble:true,//是否是困难生
        employment_type:'',
        employment_status:'',
        datePickO:{},//时间选择的值
        departmentRegionShow:false,// 单位所在地
        fieldRemark:{}, // 字段备注
        signMode:0,//签约类型
        signModeOptions:[],//签约类型选项
        autoGenerateEmploymenNumber:0,// 是否自动生成协议书编号
        errorFileList:[],// 错误字段列表
        studyAbroadCollege:{}//留学院校信息
    },
    mutations:{
        educationChange(state,value){
            state.education = value;
        },
        trainingModeChange(state,value){
            state.training_mode = value;
        },
        majorListChange(state,value){
            state.major_list = value;
        },
        isDirectionalChange(state,value){
            state.isDirectional = value;
        },
        isTroubleChange(state,value){
            state.isTrouble = value;
        },
        employmentTypeChange(state,value){
            state.employment_type = value;
        },
        employmentStatusChange(state,value){
            state.employment_status = value;
        },
        datePickerChange(state,value){
            state.datePickO[value.field] = value.value;
        },
        departmentRegionShowChange(state,value){
            state.departmentRegionShow = value;
        },
        fieldRemarkChange(state,obj){
            Object.assign(state.fieldRemark,obj||{})
        },
        signModeChange(state,value){
            state.signMode = value;
        },
        signModeOptionsChange(state,value){
            state.signModeOptions = value;
        },
        autoGenerateEmploymenNumberChange(state,value){
            state.autoGenerateEmploymenNumber = value;
        },
        setErrorFileList(state,value){
            state.errorFileList = value
        },
        /**留学院校改变 */
        studyAbroadCollegeChange(state,value){
            state.studyAbroadCollege = value;
        },
    },
    getters:{
        getMajorList:(state) =>{
            return state.major_list.filter((item) => item.education == state.education);
        }
    }

})