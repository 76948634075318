import { JosnData } from "@/utils/JsonData";
import { StorgeUtils } from "@/utils/StorageUtils";
import Api from "@/http/Api";
import { store } from "../state/store"
export class dict {
  public async GetDict(dictName: string | undefined): Promise<dictitem[]> {
    let retdict: dictitem[] = [];
    if (dictName) {
      const dictVersion = JSON.parse(localStorage.getItem('dictVersion') || '{}');
      // 根据字典版本信息判断是否直接使用本地存储的字典
      if (dictVersion[dictName] && !dictVersion[dictName].needUpdate) {
        retdict = JSON.parse(localStorage.getItem(`/dict/${dictName}`) as string) || [];
      }
    }
    if (retdict.length) {
      null
    } else if (dictName == 'school_length') {
      retdict = JSON.parse(JosnData.schoolLength)
    } else if (dictName == 'sign_mode') {
      retdict = JSON.parse(JosnData.signModeOptions)
    } else if (dictName == 'source_major') {
      const year = StorgeUtils.get('year');
      const collegeid = StorgeUtils.get('collegeid');
      const facultyid = StorgeUtils.get('facultyid');
      const education = store.state.education;
      if (year && collegeid && facultyid && education) {
        try {
          const res = await Api.getSourceMajor(collegeid, year, facultyid, education)
          //console.log(res.data);
          if (res.status && res.data) {
            for (const item of res.data) {
              item.text = item.name;
              item.code = item.source_code;
            }
            retdict = res.data;
          } else {
            retdict = [];
          }
        } catch (err) {
          null
        }
      }
    } else if (dictName == 'employment_type' || dictName == 'employment_status') {
      const year = StorgeUtils.get('year');
      if (year) {
        try {
          const res = await Api.getDictByYear(dictName, year)
          if (res.status && res.data) {
            retdict = res.data;
          } else {
            retdict = [];
          }
        } catch (err) {
          null
        }
      }
    } else {
      if (dictName) {

        try {
          const res = await Api.getDict(dictName)
          if (res && res.status) {
            retdict = res.data
            const dictversion = JSON.parse(localStorage.getItem('dictVersion') || '{}');
            if (dictversion[dictName]) {
              dictversion[dictName].needUpdate = false;
              localStorage.setItem('dictVersion', JSON.stringify(dictversion));
              localStorage.setItem(`/dict/${dictName}`, JSON.stringify(retdict))
            }
          }
        } catch (err) {
          null
        }
      }
    }
    return new Promise(resolve => {
      resolve(retdict);
    })
  }

  public Lock(dictdata: Array<dictitem>, lockvalues: string[]): Array<dictitem> {
    return dictdata.filter((item) => lockvalues.indexOf(item.text) != -1);
  }

  public Except(dictdata: Array<dictitem>, exceptvalues: string[]): Array<dictitem> {
    return dictdata.filter((item) => exceptvalues.indexOf(item.text) == -1);
  }
  public PullDictVersion(): void {
    Api.getDictVersion().then((res) => {
      if (!res.status) return
      const oldVersion = JSON.parse(localStorage.getItem('dictVersion') || '{}');
      res.data.list.forEach((v: any) => {
        const oldVersionItem = oldVersion[v.dict_type];
        if (oldVersionItem?.versionid !== v.versionid) {
          oldVersion[v.dict_type] = v;
          oldVersion[v.dict_type].needUpdate = true;
        }
      });
      localStorage.setItem('dictVersion', JSON.stringify(oldVersion));
    });
  }
}

export class dictitem {
  text!: string;
  code!: string;
  level?: number;
  parentcode?: string;
  parenttext?: string;
  relation_desc?: string;
  relation_code?: string;
  order?: number;
  disabled?:boolean;
}

export class majoritem {
  code!: string;
  education!: string;
  education_text!: string;
  educations!: Array<education>;
  is_recommend!: boolean;
  majorid!: string;
  name!: string;
  source_code!: string;
  source_code_name?: string | null;
  student_active?: number;
  student_count?: number;
}

class education {
  code!: string;
  text!: string;
}
