<template>
  <!-- 替补末尾空缺 -->
  <div class="form-components" style="margin: 0;"></div>
  <!-- 占用一整行确保下行为第一个 -->
  <div class="form-components" style="margin: 0;flex: unset"></div>
  <div v-if="item&&!((item.field=='business_license'&&signMode!=1)||(item.field=='protocol_photos'&&signMode==1))" class="form-components">
    <div class="label">
      <span class="remind-ball" v-if="item.required">*</span>{{ item.name }}
    </div>
    <div class="image-list">
      <el-image :key="image" :src="image" :fit="fit" />
    </div>
  </div>
</template>
      
  <script lang="ts">
import { defineComponent, PropType, reactive, ref, inject } from "vue";
import { imageitemdata } from "../form/dataform";
export default defineComponent({
  name: "cfImageshow",
  props: {
    item: {
      type: Object as PropType<imageitemdata>,
      default: null,
    },
    value: String,
  },
  emits: ["input", "valuechange"],
  setup(props, context) {
    const datavalue = ref(props.value);
    const fit = ref<string>("contain");
    const image = ref<string>("");
    const signMode: any = inject("signMode");
    if (datavalue.value) {
      image.value = datavalue.value;
      context.emit("valuechange", image.value);
    }
    return {
      datavalue,
      fit,
      image,
      signMode,
    };
  },
});
</script>
<style>
.image-list {
  margin-top: 0px;
  width: 120px;
  /* height: 120px; */
}
</style>
  