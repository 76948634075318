<template>
  <el-steps :active="step" align-center>
    <el-step title="生源信息" />
    <el-step title="签约信息" />
  </el-steps>
  <div v-show="step == 0">
    <dformVue
      v-if="showTalent"
      :formconfig="schema"
      :data="dataTalent"
    ></dformVue>
    <div class="btn-wrap" v-if="showTalent">
      <el-button type="primary" @click="nextPage()">下一页</el-button>
    </div>
  </div>
  <div v-show="step == 1">
    <dformVue class="step2" :formconfig="signForm" :data="dataSign"></dformVue>
    <div class="btn-wrap" v-if="showSign">
      <el-button type="primary" @click="prevPage()">上一页</el-button>
      <el-button type="primary" @click="submit()">提交</el-button>
    </div>
  </div>
</template>
  <script lang="ts">
import { defineComponent, provide, reactive, ref, toRefs } from "vue";
import { formschema, FormItem, fileditem } from "../form/dataform";
import dformVue from "../components/dform.vue";
import { getForm } from "../form/Forms";
import { ElMessage } from "element-plus";
import { FormValidators } from "../form/FormValidators";
import { StorgeUtils } from "@/utils/StorageUtils";
import Api from "@/http/Api";
import { useStore } from "vuex";
import { key } from "@/state/store";


export default defineComponent({
  name: "collegesSetupSign",
  components: {
    dformVue,
  },
  setup() {
    const store = useStore(key);
    const schema = reactive(new Array<formschema>()); // 生源表单
    const signForm = reactive(new Array<formschema>()); // 签约表单
    const dataTalent = reactive(new Map<string, any>()); // 生源数据
    const dataSign = reactive(new Map<string, any>()); // 签约数据
    const showTalent = ref<boolean>(false); // 生源显示隐藏
    const showSign = ref<boolean>(false); // 签约显示隐藏
    const selfTalentFieldlist = ref<string>(""); // 生源自定义部分表单
    const selfDepartmentFieldList = ref<string>(""); // 自定义签约部分表单
    const signModePopup = ref<boolean>(false);
    const signMode = ref<number>(0);
    const step = ref<number>(0);
    const year = ref<string>("");
    const no = ref<string>("");
    const gollegeinfo = reactive({
      page: "collegesSetupSign",
      time: '',
    });
    // no.value = StorgeUtils.get('no');

    const signParams = reactive({
      talent_info: {},
      department_info: {},
      profile_address: {},
      talent_info_self_field_list: Array<fileditem>(),
      department_info_self_field_list: Array<fileditem>(),
    });
    const extractHash = (url) => {
      const hashIndex = url.indexOf('#');
      if (hashIndex !== -1) {
        return url.slice(hashIndex + 1);
      }
      return '';
    }
    const getRecordidFromUrl = () => {
      let url = extractHash(window.location.href);
      let querys = url.substring(url.indexOf("?") + 1).split("&");
      let result: any[] = [];
      for (let i = 0; i < querys.length; i++) {
        let temp = querys[i].split("=");
        console.log(temp);
        if (temp.length < 2) {
          result[temp[0]] = "";
        } else {
          result[temp[0]] = temp[1];
        }
      }

      no.value = result["no"];
      let tempToken = result["token"];
      let tempAccountid = result["accountid"];
      let userType = result["usertype"];
      gollegeinfo.time = result["t"]
      //token accountid usertype 小程序传参
      if(tempToken){
        StorgeUtils.set('token',tempToken);
      }
      if(tempAccountid){
        StorgeUtils.set("accountid",tempAccountid);
      }
      if(userType){
        StorgeUtils.set("usertype",userType);
      }
    };
    getRecordidFromUrl();

    //使用表单名称载入对应表单
    const LoadExtForm = (formname: string, formschema: any) => {
      const form = getForm(formname);
      if (form) {
        formschema = formschema.push(form);
      }
    };

    //调用接口加载表单 根据类型就业类别加载
    const loadExtFormByHttp = (employmentType: string) => {
      Api.collegeSignInfoForm(year.value, employmentType).then((res) => {
        if (res.status) {
          store.commit("fieldRemarkChange",res.data.required_field_remark);
          const formName = `sign_${res.data.employment_type}`;
          const index = signForm.findIndex((item) => item.name == formName);
          let signSelfList = res.data.self_field_list;
          //debugger;
          if (index) {
            let signFormData = signForm[index].data
            for (let item of res.data.field_list) {
              let location = signForm[index].data.length - 1;
              signForm[index].data.splice(location, 0, item);
            }
            //signForm[index].data.unshift()
            //console.log(signForm[index].data)
            if (signSelfList && signSelfList.length > 0) {
              for (let item of signSelfList) {
                let location = signForm[index].data.length - 1;
                signForm[index].data.splice(location, 0, item);
              }
              selfDepartmentFieldList.value = JSON.stringify(signSelfList);
            }
            signFormData.push(signFormData.splice(signFormData.findIndex(v=>v.field==='business_license'),1).pop() as any)
            signFormData.push(signFormData.splice(signFormData.findIndex(v=>v.field==='protocol_photos'),1).pop() as any)
          }
          showSign.value = true;
        }
        //console.log(res);
        console.log(signForm);
      });
    };

    //卸载表单名称以后加载的表单
    const UnloadExtFormAfter = (formname: string, formschema: any) => {
      const index = formschema.findIndex((item) => item.name == formname);
      console.log(index);
      if (index > -1) {
        formschema.splice(index, 1);
      }
    };
    //清空签约表单值  在切换就业类别时
    const emptySignData = (except:Array<string>=[]) => {
      dataSign.forEach((value, key) => {
        if(!except.includes(key)){
          dataSign.set(key, undefined);
        }
      });
    };

    //选择就业类别
    provide("signForm", signForm);
    provide("signModePopup", signModePopup);
    provide("LoadExtFrom", LoadExtForm);
    provide("UnloadExtFormAfter", UnloadExtFormAfter);
    provide("emptySignData", emptySignData);
    provide("loadExtFormByHttp", loadExtFormByHttp);
    provide("signMode", signMode);
    //获取签约表单相关信息
    Api.collegeAddSignUp(no.value).then((res) => {
      console.log(res);
      StorgeUtils.set("year", res.data.year);
      StorgeUtils.set("collegeid", res.data.collegeid);
      StorgeUtils.set("facultyid", res.data.facultyid);
      store.commit("fieldRemarkChange",res.data?.department_field?.required_field_remark);
      let fieldData = res.data.talent_field.field_data;
      let selfFieldData = res.data.talent_field.self_field_data;
      let fieldList = res.data.talent_field.field_list;
      let selfFileList = res.data.talent_field.self_field_list;
      year.value = res.data.year;

      for (let key in fieldData) {
        dataTalent.set(key, fieldData[key]);
      }
      for (let key in selfFieldData) {
        dataTalent.set(key, selfFieldData[key]);
      }
      showTalent.value = true;
      LoadExtForm("student_source", schema);
      LoadExtForm("sign_base", signForm);

      if (fieldList && fieldList.length > 0) {
        for (let item of fieldList) {
          schema[0].data.push(item);
        }
      }

      if (selfFileList && selfFileList.length > 0) {
        for (let item of selfFileList) {
          schema[0].data.push(item);
        }
        selfTalentFieldlist.value = JSON.stringify(
          res.data.talent_field.self_field_list
        );
      }
      console.log(dataTalent);
      //ata = res.talent_field.field_data;
    });

    dataSign.set("1524", "456465");
    const GetData = (form: any, data: any) => {
      //过滤不在表单堆栈中的值

      let fields = new Map<string, undefined>();
      //生成表单字段表
      //console.log(form);
      form.forEach((form) => {
        form.data.forEach((item) => {
          fields.set(item.field, undefined);
        });
      });
      console.log(fields);
      //检查data是否在表单字段中
      let retdata = new Map<string, any>();
      data.forEach((value, key) => {
        if (fields.has(key)) retdata.set(key, value);
      });

      console.log(retdata);
      return retdata;
    };

    const formatSignParams = () => {
      let talentInfo: Map<string, any> = GetData(schema, dataTalent);
      let formData: Map<string, any> = GetData(signForm, dataSign);
      talentInfo.forEach((value, key) => {
        signParams.talent_info[key] = value;
      });
      formData.forEach((value, key) => {
        signParams.department_info[key] = value;
      });
      // if (formData.has("protocol_photos")) {
      //   signParams.profile_address["protocol_photos"] =
      //     formData.get("protocol_photos");
      // }
      if (selfTalentFieldlist.value) {
        let list = JSON.parse(selfTalentFieldlist.value);
        signParams.talent_info_self_field_list = []
        for (let item of list) {
          signParams.talent_info_self_field_list.push({
            field: item.field,
            value: talentInfo.get(item.field),
          });
        }
      }
      if (selfDepartmentFieldList.value) {
        let list = JSON.parse(selfDepartmentFieldList.value);
        signParams.department_info_self_field_list = []
        for (let item of list) {
          signParams.department_info_self_field_list.push({
            field: item.field,
            value: formData.get(item.field),
          });
        }
      }
    };

    const nextPage = async () => {
      let formData: Map<string, any> = GetData(schema, dataTalent);
      const result = await FormValidators.checkForm(schema[0].data, formData);
      //console.log(result);
      if (result) {
        step.value = 1;
      }
    };

    const prevPage = () => {
      step.value = 0;
    };

    const submit = async () => {
      let formData: Map<string, any> = GetData(signForm, dataSign);
      //console.log(formData);
      const rules: FormItem[] = [];
      signForm.forEach((form) => {
        form.data.forEach((item) => {
          rules.push(item);
        });
      });
      console.log(formData);
      const result = await FormValidators.checkForm(rules, formData);
      if (result) {
        //处理签约提交数据
        formatSignParams();
        Api.collegeAddSubmit(signParams).then((res) => {
          console.log(res);
          if (res.status) {
            ElMessage({
              message: "新增成功",
              type: "success",
              offset: 50,
            });
            let obj = JSON.parse(JSON.stringify(gollegeinfo));
            console.log('123123123')
            window.parent.postMessage(obj, "*");
          }
        });
        console.log(signParams);
      }
    };

    return {
      schema,
      signForm,
      showTalent,
      showSign,
      dataTalent,
      dataSign,
      GetData,
      nextPage,
      prevPage,
      submit,
      step,
    };
  },
  computed: {},
});
</script>
<style scoped>
  .btn-wrap {
    height: 60px;
    margin: 20px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
  }
  .sign-select {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
  }
  .sign-select .sign-type {
    width: 120px;
    height: 140px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .sign-select .sign-type img {
    width: 60px;
    height: 60px;
  }
  .sign-select .sign-type div {
    font-size: 12px;
    color: #cccccc;
  }
</style>