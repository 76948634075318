<template>
  <div v-if="item" class="form-components" :class="{'el-form-item is-error':store.state.errorFileList.includes(item.field)}">
    <div class="label"><span class="remind-ball" v-if="item.required">*</span>{{ item.name }}</div>
      <el-input-number
        v-model="datavalue"
        :disabled="item.is_disable"
        :placeholder="item.prompt_msg"
        @change="change"
      />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from "vue";
import { itemdata } from "../form/dataform";
import { useStore } from 'vuex'
import { key } from './../state/store' 

export default defineComponent({
  name: "cfNumber",
  props: {
    item: {
      type: Object as PropType<itemdata>,
      default: null,
    },
    value: Number,
  },
  emits: ["input"],
  setup(props,context) {
    const store = useStore(key);

    const datavalue = ref(props.value);

    const change = () =>{
      console.log(6655,datavalue.value)
      context.emit('input',datavalue.value)
    }
    return { datavalue,change,store }
  }
});
</script>
