<template>
  <!-- 占位标签，防止上一行只有一个字段时整行展示 -->
  <template v-if="item.field=='department_region'">
    <!-- 替补末尾空缺 -->
    <div class="form-components" style="margin: 0;"></div>
    <!-- 占用一整行确保下行为第一个 -->
    <div class="form-components" style="margin: 0;flex: unset"></div>
  </template>
  <div v-if="item" class="form-components" :class="{'el-form-item is-error':store.state.errorFileList.includes(item.field)}">
    <div class="label">
      <el-tooltip
        v-if="item.remark||stateFieldRemark[item.field]"
        :content="item.remark||stateFieldRemark[item.field]"
        popper-class="remark-popper"
        placement="top-start"
        trigger="click"
      >
        <img src="https://images.goworkla.cn/wxAppletImg/EnergyValue_result_about.png" class="label-remark"/>
      </el-tooltip>
      <span class="remind-ball" v-if="item.required">*</span>{{ item.name }}
    </div>
    <div class="content">
      <el-select v-model="provience" class="m-2" @change="provienceChange"  :disabled="item.is_disable">
        <el-option
          v-for="item in provinceList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
      <el-select v-model="city" class="m-2" @change="cityChange" :disabled="item.is_disable">
        <el-option
          v-for="item in cityList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
      <el-select v-model="county" class="m-2" @change="countyChange" :disabled="item.is_disable">
        <el-option
          v-for="item in countyList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject, PropType, ref, toRefs } from "vue";
import { selectitemdata } from "../form/dataform";
import { dict} from "../form/dictProvider";
import { areaitem } from "../form/dataform"
import { useStore } from "vuex";
import { key } from "./../state/store";


export default defineComponent({
  name: "cfAreaSelect",
  props: {
    item: {
      type: Object as PropType<selectitemdata>,
      default: null,
    },
    value: String,
  },
  emits: ["input", "valuechange"],
  setup(props, context) { 
    const store = useStore(key);
    let item = toRefs(props).item.value;
    let datavalue = ref(props.value); 
    let allProvinceList :Array<areaitem> = [];
    let allCityList : Array<areaitem> = [];
    let allCountyList : Array<areaitem> = [];
    let provinceList = ref<areaitem[]>([])
    let cityList =  ref<areaitem[]>([]);
    let countyList = ref<areaitem[]>([])
    let provience  = ref<string>('');
    let city = ref<string>('');
    let county = ref<string>('');
    const dictprovide = inject<dict>("dict");
    if (dictprovide) {
      if (item) {
        if(item.field=='department_region'){
          store.commit("departmentRegionShowChange", true);
        }
        //console.log(item)
        let source = item?.dict_type;
        let listRes = dictprovide.GetDict(source);
        listRes.then( res => {
          //对地区数据进行分类
          res.forEach((val) =>{
              if(val.level == 1){
                  let data:areaitem = {label: val.text, value: val.code}
                  allProvinceList.push(data);
              }
              if(val.level == 2){
                  let data:areaitem = {label: val.text, value: val.code}
                  allCityList.push(data);
              }
              if(val.level == 3){
                  let data:areaitem = {label: val.text, value: val.code}
                  allCountyList.push(data);
              }
          })

          provinceList.value = allProvinceList;
          //数据初始化
          if(datavalue.value){
              //console.log(datavalue.value)
              if(datavalue.value.length == 6){
              provience.value = datavalue.value.substring(0,2);
              cityList.value = allCityList.filter((item) => item.value.substring(0,2) == provience.value)
              city.value = datavalue.value.substring(0,4);
              countyList.value = allCountyList.filter((item) => item.value.substring(0,4) == city.value);
              county.value = datavalue.value;
              } else {
                datavalue.value = ''
                context.emit("valuechange", '')
              }
          }
        })
       
      }
    }
    //省份切换
    const provienceChange = (val) =>{
        provience.value = val;
        cityList.value = allCityList.filter((item) => item.value.substring(0,2) == provience.value) 
        countyList.value = [];
        city.value = '';
        county.value = '';
        context.emit("valuechange", '')
    }
    //城市切换
    const cityChange = (val) =>{
        city.value = val;
        countyList.value = allCountyList.filter((item) => item.value.substring(0,4) == city.value)
        county.value = '';
        context.emit("valuechange", '')
    }
    //区域切换
    const countyChange = (val) =>{
        county.value = val;
        context.emit("valuechange", county.value)
    }
    return {  
        datavalue, 
        provinceList,   
        cityList,
        countyList,
        provience,
        city,
        county,
        provienceChange,
        cityChange,
        countyChange,
        store,
    };
  },
  computed:{
    stateFieldRemark:function(){
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return this.store.state.fieldRemark
    }
  }
});
</script>
<style scoped>
.form-components .content .el-select{
    /* width: 110px; */
}
@media screen and (max-width:750px){
  .form-components .content .el-select{
      width: 32%;
  }
}
</style>
