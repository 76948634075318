import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "label" }
const _hoisted_2 = {
  key: 0,
  class: "remind-ball"
}
const _hoisted_3 = { class: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!

  return (_ctx.item)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["form-components multiple", {'el-form-item is-error':_ctx.store.state.errorFileList.includes(_ctx.item.field)}])
      }, [
        _createElementVNode("div", _hoisted_1, [
          (_ctx.item.required)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, "*"))
            : _createCommentVNode("", true),
          _createTextVNode(_toDisplayString(_ctx.item.name), 1)
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_el_input, {
            modelValue: _ctx.datavalue,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.datavalue) = $event)),
            rows: _ctx.item.row,
            type: "textarea",
            disabled: _ctx.item.is_disable,
            placeholder: _ctx.item.prompt_msg,
            onInput: _ctx.change
          }, null, 8, ["modelValue", "rows", "disabled", "placeholder", "onInput"])
        ])
      ], 2))
    : _createCommentVNode("", true)
}