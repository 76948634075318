import {createRouter,createWebHistory,RouteRecordRaw,createWebHashHistory} from 'vue-router';
import signCenter from '../views/signCenter.vue';
import collegesSetupSign from '../views/collegesSetupSign.vue';
import signCheck from '../views/signCheck.vue';
import rescindSign from '../views/rescindSign.vue';
import  App  from "../App.vue";
import collegeAmendSign from '../views/collegeAmendSign.vue';
import imgView from '../views/imgView.vue';

const routes :Array<RouteRecordRaw> = [
    {
        path:'/',
        name:'/',
        component:App
    },
    {
        // 学生签约
        path:'/signCenter',
        name:'/signCenter',
        component:signCenter
    },
    {
        //解约
        path:'/rescindSign',
        name:'/rescinSign',
        component:rescindSign
    },
    {
        // 高校创建签约
        path:'/collegesSetupSign',
        name:'/collegesSetupSign',
        component:collegesSetupSign
    },
    {
        // 高校审核签约
        path:'/signCheck',
        name:'/signCheck',
        component:signCheck
    },
    {
        // 高校修改签约
        path:'/collegeAmendSign',
        name:'/collegeAmendSign',
        component:collegeAmendSign
    },
    {
        // 图片查看
        path:'/imgView',
        name:'/imgView',
        component:imgView
    },
]

const router = createRouter({
    history:createWebHashHistory(),
    routes
})

export default router