export class StorgeUtils{
    public static storage:any = localStorage;
    
    public static get(key:string):string {
        return this.storage[key] || '';
    }

    public static set(key:string, value:any):void {
        this.storage[key] = value;
    }

    public static remove(key:string):any {
        this.storage.removeItem(key);
    }

    public static clear(){
        this.storage.clear();
    }
}