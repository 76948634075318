<template>
    <el-image-viewer :key="imageIndex" ref="imageViewerRef" :url-list="imageList" :initial-index="imageIndex"
        @close="close"></el-image-viewer>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
export default defineComponent({
    setup() {
        const imageViewerRef = ref()
        const route = useRoute()
        const imageList = ref([])
        const imageIndex = ref(0)
        const getImg = () => {
            let imageListJson = localStorage.getItem('imageListOnce')
            localStorage.removeItem('imageListOnce')
            if (imageListJson) {
                imageList.value = JSON.parse(imageListJson)
            } else {
                imageList.value = []
            }
            let imageIndexJson = localStorage.getItem('imageIndexOnce')
            localStorage.removeItem('imageIndexOnce')
            if (imageIndexJson) {
                imageIndex.value = JSON.parse(imageIndexJson)
            } else {
                imageIndex.value = 0
            }
            if (imageViewerRef.value) {
                imageViewerRef.value.setActiveItem(imageIndex.value)
            }
        }
        const close = () => {
            window.close()
        }
        watch(() => route.query, () => {
            debugger
            getImg()
        }, { immediate: true, deep: true })
        return { imageList, imageIndex, close }
    }
})
</script>

<style scoped></style>