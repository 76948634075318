//判断当前平台类型 pc mobile wx wxmini
export function getPlatform(): string {
    const ua:any = navigator.userAgent.toLowerCase();
    // 企业微信
    const isWXWork:boolean = ua.match(/wxwork/i) == 'wxwork';
    // 微信浏览器
    const isWeixin:boolean = !isWXWork && ua.match(/MicroMessenger/i) == 'micromessenger';
    let isMobile = false;
    let isDesktop = false;
  
    const jsonString = JSON.stringify(ua);
    const wexinMiniLabel = "miniprogram";
    let platform = '';
  
    if (navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|IEMobile)/i)) {
        isMobile = true
    } else {
        isDesktop = true
    }
    
    if (isDesktop) {
        //console.log('getPlatform==== 电脑端浏览器')
        platform = 'pc'
    } else if (isWeixin) {
        //console.log('getPlatform==== 微信浏览器')
        platform = 'wx'
    }else if(isMobile) {
        //console.log('getPlatform====移动端浏览器')
        platform = 'mobile'
    }
    if(jsonString.indexOf(wexinMiniLabel)>=0){
        platform = 'wxmini'
    }

    return platform;
  }
  