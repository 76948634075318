<template>
  <div v-if="item" class="form-components" :class="{'el-form-item is-error':store.state.errorFileList.includes(item.field)}">
    <div class="label">
      <el-tooltip
        v-if="item.remark||stateFieldRemark[item.field]"
        :content="item.remark||stateFieldRemark[item.field]"
        popper-class="remark-popper"
        placement="top-start"
        trigger="click"
      >
        <img src="https://images.goworkla.cn/wxAppletImg/EnergyValue_result_about.png" class="label-remark"/>
      </el-tooltip>
      <span class="remind-ball" v-if="item.required">*</span>{{ item.name }}
    </div>
    <div class="content">
      <el-date-picker
        v-model="datavalue"
        type="date" 
        style="width:100%"
        :placeholder="item.prompt_msg" 
        :disabled="item.is_disable"
        :disabled-date="disabledDate"
        value-format="YYYY-MM-DD"
        @change="change"
      />
    </div>
    
  </div>
</template>


<script lang="ts">
import { defineComponent, onMounted, PropType, ref,toRefs } from "vue";
import { itemdata } from "../form/dataform";
import { compareAsc } from 'date-fns'
import { useStore } from "vuex";
import { key } from "./../state/store";

export default defineComponent({
  name: "cfDate",
  props: {
    item: {
      type: Object as PropType<itemdata>,
      default: null,
    },
    value: String,
  },
  emits: ["input"],
  setup(props,context) {
    const store = useStore(key);
    let item = toRefs(props).item.value;
    const datavalue = ref(props.value||(props.item as any).lockvalue);
    store.commit("datePickerChange", {field:item.field,value:datavalue})
    const change = () =>{
      store.commit("datePickerChange", {field:item.field,value:datavalue})
      context.emit('input',datavalue.value)
    }
    const disabledDate = (date:Date) => {
      if (item.field == 'start_time') {
        let end_time = store.state.datePickO['end_time'];
        if (end_time) {
          return compareAsc(end_time, date) < 0
        }
      } else if (item.field == 'end_time') {
        let start_time = store.state.datePickO['start_time'];
        if (start_time) {
          return compareAsc(start_time, date) > 0
        }
      }
      return false
    }
    
    return { datavalue,change,disabledDate,store }
  },
  computed:{
    stateFieldRemark:function(){
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return this.store.state.fieldRemark
    }
  }

});
</script>
