<template>
  <div class="state_box">
    <div class="d_f jc_sb state_signing_status">
      <span>流程</span>
      <span v-if="signingStatus">签约状态：{{ signingStatus }}</span>
    </div>
    <div class="state_list">
      <div class="d_f ai_fs state_item" v-for="(item, index) in requestStateList" :key="index">
        <img class="state_item_img" src="https://images.goworkla.cn/wxAppletImg/portrait.png" />
        <div class="f_1">
          <div class="d_f jc_sb">
            <span class="state_item_state">{{ item.stateStr }}</span>
            <span class="state_item_time">{{ item.state_time }}</span>
          </div>
          <div class="state_item_name">{{ item.state_accountname || '' }}</div>
          <div v-if="[5, 6, 7, 8].some(v => item.agreement_state === v)" class="state_item_remark">{{ item.remark || '' }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, watch, PropType, provide, reactive, ref } from "vue";
import { requestStateitem } from "../form/dataform";
import { format  } from 'date-fns'
export default defineComponent({
  name: "dProcess",
  components: {},
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const statebyO = {
      college: "学校",
      faculty: "院系",
      talent: "学生",
      department: "企业"
    }
    const stateA = ["已创建", "签约中", "确认签约", "审核通过", "已解约", "拒绝签约", "退回给学生", "退回给企业", "驳回给院系"]
    const requestStateList = reactive(new Array<requestStateitem>())
    const signingStatus = ref('')
    let fieldDepartmentName = ''
    props.data.request_state_list.forEach((v: requestStateitem) => {
      let stateStr = statebyO[v.stateby] + stateA[v.agreement_state]
      if(v.stateby=='talent'&&v.agreement_state==-1){
        stateStr='学生保存草稿'
      }else      if(v.stateby=='talent'&&v.agreement_state==3){
        stateStr='学生确认签约'
      }else if(v.remark === '高校修改签约'){
        stateStr='学校修改签约'
      }
      let state_time = format(v.state_time,'yyyy-MM-dd HH:mm')
      let state_accountname = v.state_accountname
      if (v.stateby == 'department' && !state_accountname) {
        if (!fieldDepartmentName) {
          let field_list = props.data.department_field_list
          fieldDepartmentName = (field_list.filter((field: { field: string; }) => field.field == 'department_name')[0] || {}).default_value
        }
        state_accountname = fieldDepartmentName
      }
      requestStateList.push({
        ...v,
        state_time,
        stateStr,
        state_accountname,
      })
    })
    if(props.data.current_state == 6){
      signingStatus.value = '撤回中'
    }else if(props.data.current_state == 7){
      signingStatus.value = '已撤回'
    } else if (props.data.agreement_state == '2') {
      if (props.data.stateby == 'talent' && props.data.audit_mode) {
        signingStatus.value = '待院系审核'
      } else {
        signingStatus.value = '待学校审核'
      }
    } else if (props.data.agreement_state == '3') {
      signingStatus.value = '已通过'
    } else if (props.data.agreement_state == '4') {
      signingStatus.value = '已解约'
    } else if (props.data.agreement_state == '5') {
      if (props.data.stateby == 'department') {
        signingStatus.value = '企业拒绝'
      } else if (props.data.stateby == 'talent') {
        signingStatus.value = '人才拒绝'
      } else {
        signingStatus.value = '未通过'
      }
    } else if (props.data.agreement_state == '8') {
      signingStatus.value = '待院系审核'
    }else if (props.data.sign_mode == '1') {// 线上签约状态
      if (props.data.agreement_state == '0') {
        if (props.data.stateby == 'talent') {
          signingStatus.value = '待企业审核'
        }
      } else if (props.data.agreement_state == '1') {
        if (props.data.stateby == 'department') {
          signingStatus.value = '待学生确认'
        }
     
      } else if (props.data.agreement_state == '6' || props.data.agreement_state == '7') {
        if (props.data.stateby == 'faculty' || props.data.stateby == 'college') {
          signingStatus.value = '签约中退回'
        } else {
          signingStatus.value = '未通过'
        }
      }
    } else if (props.data.sign_mode == '2') {// 打印协议书签约
      if (props.data.agreement_state == '0') {
        if (props.data.stateby == 'talent') {
          signingStatus.value = '学生创建未提交'
        }
      }
    } else if (props.data.sign_mode == '0') {// 已签协议书备案
      if (props.data.agreement_state == '0') {
        if (props.data.stateby == 'talent') {
          signingStatus.value = '学生创建未提交'
        }
      }
    }
    return { requestStateList, signingStatus };
  }
})
</script>
<style>
.d_f {
  display: flex;
  align-items: center;
}

.ai_fs {
  align-items: flex-start;
}

.jc_sb {
  justify-content: space-between;
}

.f_1 {
  flex: 1;
}

.state_box {
  margin: 10px 16px;
  border: 1px solid gainsboro;
}

.state_signing_status {
  font-size: 17px;
  color: #1f2f3d;
  line-height: 24px;
  padding: 10px 16px;
}
.state_list {
 max-height: 600px;
 overflow-y: auto;
}

.state_item {
  position: relative;
  padding: 10px 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
}

.state_item:not(:last-child):before {
  content: '';
  width: 1px;
  height: calc(100% - 50px);
  top: 60px;
  left: 41px;
  background-color: #d6d6d6;
  position: absolute;
}

.state_item_img {
  width: 50px;
  min-width: 50px;
  height: 50px;
  margin-right: 20px;
  vertical-align: middle;
}

.state_item_state {
  padding-top: 3px;
  color: #bebebe;
}

.state_item_time {
  padding-top: 3px;
  color: #101010;
}

.state_item_name {
  color: #101010;
  font-weight: 600;
  height: 19px;
}

.state_item_remark {
  padding-top: 3px;
  color: #778082;
  overflow-wrap: anywhere;
}

@media screen and (min-width:750px) {
  .state_box {
    width: 600px;
    margin: 10px auto;
  }
}
</style>