<template>
  <!-- 占位标签，防止上一行只有一个字段时整行展示 -->
  <template v-if="item.type === 'textarea'">
    <!-- 替补末尾空缺 -->
    <div class="form-components" style="margin: 0;"></div>
    <!-- 占用一整行确保下行为第一个 -->
    <div class="form-components" style="margin: 0;flex: unset"></div>
  </template>
  <div v-if="item&&seen&&!(item.field=='employerment_agreement_number'&&signMode&&!datavalue&&autoGenerateEmploymenNumber)" class="form-components" :class="{'el-form-item is-error':store.state.errorFileList.includes(item.field)}" :style="item.type === 'textarea'?'flex:unset':''">
    <div class="label" v-if="!(item.field=='department_address'&&departmentRegionShow)"           
>
      <el-tooltip
        v-if="item.remark||stateFieldRemark[item.field]"
        :content="item.remark||stateFieldRemark[item.field]"
        popper-class="remark-popper"
        placement="top-start"
        trigger="click"
      >
        <img src="https://images.goworkla.cn/wxAppletImg/EnergyValue_result_about.png" class="label-remark"/>
      </el-tooltip>
      <span class="remind-ball" v-if="item.required">*</span>{{ item.name }}
    </div>
    <div class="label minishow" v-if="item.field=='department_address'&&departmentRegionShow">

    </div>
    <div class="content">
      <el-input
        v-model.trim="datavalue"
        :disabled="item.is_disable"
        :placeholder="item.prompt_msg||`请输入${item.name}`"
        :type="item.type||'text'"
        :autosize="{minRows: 2, maxRows: 4}"
        resize="none"
        @input="change"
      />
    </div>
    <div v-if="showLink&&item.field=='department_name'&&usertype!='wx'" class="form-components-link">
      <span><a target="_blank" :href="`https://aiqicha.baidu.com/s?q=${datavalue}`">爱企查</a></span>
      <span><a target="_blank" :href="`https://www.tianyancha.com/search?key=${datavalue}`">天眼查</a></span>
      <span><a target="_blank" :href="`https://www.qcc.com/search?key=${datavalue}`">企查查</a></span>
      <span><a target="_blank" href="http://www.gsxt.gov.cn/index.html">国家企业信息公示系统</a></span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref, toRefs, inject, watch } from "vue";
import { textitemdata } from "../form/dataform";
import { useStore } from 'vuex'
import { key } from './../state/store' 
import { StorgeUtils } from "@/utils/StorageUtils";


export default defineComponent({
  name: "cfText",
  props: {
    item: {
      type: Object as PropType<textitemdata>,
      default: null,
    },
    value: String,
  },
  emits: ["input"],
  setup(props,context) {
    const usertype = ref( StorgeUtils.get("usertype"))
    //console.log(props.item);
    //console.log(props.item.lockvalue)
    let item2 = toRefs(props).item.value;
    const store = useStore(key);
    let datavalue = ref<string>('');
    const signMode: any = inject("signMode");
    let tempValue:any =  props.item.lockvalue ? props.item.lockvalue : props.value;
    datavalue.value = tempValue;
    
    if(datavalue.value){
      context.emit('input',datavalue.value)
    }
    const change = () =>{
      context.emit('input',datavalue.value)
    }
    if(item2.field == 'employerment_agreement_number'&& ~~signMode.value && store.state.autoGenerateEmploymenNumber){
      item2.is_disable = true
      item2.required = false
    }
    watch(() => store.state.signMode, (val, oldVal) => {
      if (val && item2.field == 'employerment_agreement_number'&&store.state.autoGenerateEmploymenNumber) {
        item2.is_disable = true
        item2.required = false
      }
    })
    watch(() => props.value, (val, oldVal) => {
      datavalue.value = val as string;
    })
    return { usertype, datavalue, change, store,item2,signMode}
  },
  computed:{
    seen:function(){
      let result = true;
      if(this.item2.field == 'orientation_department_name'){
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        result = this.store.state.isDirectional;
      }
      // if(this.item2.field == 'employerment_agreement_number'&&this.signMode){
      //   result = !!this.datavalue
      // }
      return result;
    },
    showLink:function(){
      let result = false;
      // let href = window.location.href
      // if(['#/collegesSetupSign','#/signCheck','#/collegeAmendSign'].some(v=>href.includes(v))){
        result = true
      // }
      return result;
    },
    departmentRegionShow:function(){
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return this.store.state.departmentRegionShow
    },
    stateFieldRemark:function(){   
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return this.store.state.fieldRemark
    },
    autoGenerateEmploymenNumber:function(){
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return this.store.state.autoGenerateEmploymenNumber
    }
  }

});
</script>
<style scoped>
.form-components{
  flex-wrap: wrap;
}
.form-components-link{
  width: 100%;
  text-align: right;
}
.form-components-link span{
  padding: 0 5px;
}
.minishow{
  display: none;
}
@media screen and (max-width:750px) {
  .form-components-link{
    font-size: 12px;
  }
  .minishow{
    display: block;
  }
}
</style>