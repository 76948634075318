<template>
  <div>
    <div v-if="schema&&schema.length" class="dform_head">
      <div class="dform_head_l">
        <div class="dform_head_title_befor"></div>
        <div class="dform_head_title">生源信息</div>
        <div class="dform_head_title_after" :class="{turnRight:hiddeTalentForm}" @click="hiddeTalentForm=!hiddeTalentForm"></div>
      </div>
      <!-- <div class="dform_head_r">
        <div v-if="studentData.audit_status == 0" class="dform_head_more">生源待完善</div>
        <div v-if="studentData.audit_status == 1" class="dform_head_more">生源待审核</div>
        <div v-if="studentData.audit_status == 2" class="dform_head_more">生源已审核</div>
        <div v-if="studentData.audit_status == 1" class="dform_head_more_after" @click="toAuditStudent">审核>>></div>
      </div> -->
    </div>
    <dformVue v-show="!hiddeTalentForm" v-if="showTalent" :formconfig="schema" :data="dataTalent" style="transition-duration: 0.5s;"></dformVue>
  </div>
  <div>
    <div v-if="signForm&&signForm.length" class="dform_head">
      <div class="dform_head_l">
        <div class="dform_head_title_befor"></div>
        <div class="dform_head_title">签约信息</div>
      </div>
    </div>
    <dformVue class="step2" :formconfig="signForm" :data="dataSign"></dformVue>
    <div v-if="isShowNOPass" class="btn-wrap">
      <p class="statewords">{{ isShowNOPass }}</p>
    </div>
    <div v-if="requestStateData.request_state_list?.length" class="dform_head">
      <div class="dform_head_l">
        <div class="dform_head_title_befor"></div>
        <div class="dform_head_title">流程信息</div>
      </div>
    </div>
    <dProcess v-if="requestStateData.request_state_list?.length" :data="requestStateData"></dProcess>
    <div class="btn-wrap">
      <el-button v-show="editBtn" type="primary" @click="toEdit">修改</el-button>
      <el-button v-show="pass" type="primary" @click="approve()">通过</el-button>
      <el-button v-show="pass" type="primary" @click="disagreedialog = true">未通过</el-button>
      <el-button v-show="backFaculty" type="primary"
        @click="rejectDialogShow = true">驳回院系</el-button>
      <el-button v-show="handBack" type="primary" @click="handBackDialogShow = true">退回签约</el-button>
      <!-- <el-button v-show="pass" type="primary" @click="goamend()"
        >去修改</el-button
      > -->
      <el-button v-show="isReset && pattern != '1'" type="primary" @click="againcheck()">重新审核</el-button>
      <el-button v-show="pass" type="primary" @click="validationrules()">规则校验</el-button>
    </div>
  </div>
  <!-- 校验规则弹窗 -->
  <el-dialog v-model="errorshow" title="规则校验" center custom-class="custom_el_dialog">
    <div>
      <div v-for="item of checkerror" :key="item">
        <div class="err-text">{{ item.error_msg }}</div>
      </div>
    </div>
  </el-dialog>
  <!-- 拒绝签约弹框 -->
  <el-dialog v-model="disagreedialog" title="请填写未通过原因" center custom-class="custom_el_dialog">
    <el-form :model="checkinfo">
      <el-form-item label="未通过原因">
        <el-input v-model="checkinfo.reason" autocomplete="off" />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="disagreedialog = false">取消</el-button>
        <el-button type="primary" @click="disagree()">确定</el-button>
      </span>
    </template>
  </el-dialog>
  <!-- 退回签约弹框 -->
  <el-dialog v-model="handBackDialogShow" title="退回签约" center custom-class="custom_el_dialog">
    <el-form :model="handBackConfig">
      <el-form-item label="退回单位">
        <el-select v-model="handBackConfig.account_type" style="width:100%">
          <el-option label="退回人才" value="talent" />
          <el-option label="退回企业" value="department" />
        </el-select>
      </el-form-item>
      <el-form-item label="退回原因">
        <el-input v-model="handBackConfig.reason" autocomplete="off" type="textarea"
          :autosize="{ minRows: 2, maxRows: 4 }" resize="none" />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handBackDialogShow = false">取消</el-button>
        <el-button type="primary" @click="handBackSign()">确定</el-button>
      </span>
    </template>
  </el-dialog>
  <!-- 驳回院系弹框 -->
  <el-dialog v-model="rejectDialogShow" title="驳回院系" center custom-class="custom_el_dialog">
    <el-form :model="rejectConfig">
      <el-form-item label="驳回原因">
        <el-input v-model="rejectConfig.value" autocomplete="off" type="textarea" :autosize="{ minRows: 2, maxRows: 4 }"
          resize="none" />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="rejectDialogShow = false">取消</el-button>
        <el-button type="primary" @click="rejectSign()">确定</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script lang="ts">
import { defineComponent, provide, reactive, ref, toRefs } from "vue";
import { formschema, FormItem, requestStateitem, fileditem } from "../form/dataform";
import dformVue from "../components/collegeform.vue";
import dProcess from "../components/dProcess.vue"
import { getForm } from "../form/Forms";
import { FormValidators } from "../form/FormValidators";
import { collapseContextKey, ElMessage } from "element-plus";
import Api from "@/http/Api";
import { StorgeUtils } from "@/utils/StorageUtils";
import router from "@/router/router";
import { useStore } from "vuex";
import { key } from "@/state/store";
import { useRoute } from 'vue-router';
export default defineComponent({
  name: "signCheck",
  components: {
    dformVue,
    dProcess,
  },
  setup() {
    const route = useRoute()
    const store = useStore(key);
    let userRole = '';
    const schema = reactive(new Array<formschema>());
    const signForm = reactive(new Array<formschema>());
    const dataTalent = reactive(new Map<string, any>());
    const dataSign = reactive(new Map<string, any>());
    const showTalent = ref<boolean>(false);
    const showSign = ref<boolean>(false);
    const selfDepartmentFieldList = ref<string>(""); // 自定义签约部分表单
    const recordid = ref<string>("");
    const signModePopup = ref<boolean>(true);
    const signTipsPopup = ref<boolean>(false);
    const checkerror = ref(new Array<string>());
    const collegestatus = ref<string>("college");
    const isShowNOPass = ref<string>(""); // 原因
    const isReset = ref<boolean>(false); // 重新审核按钮
    const editBtn = ref<boolean>(false);// 修改按钮
    const pass = ref<boolean>(false); // 通过按钮
    const handBack = ref<boolean>(false) // 退回按钮
    const backFaculty = ref<boolean>(false) // 退回按钮
    const errorshow = ref<boolean>(false); // 弹框显示隐藏
    const dialogVisible = ref<boolean>(false); //重新审核弹窗
    const disagreedialog = ref<boolean>(false); //不通过弹窗
    const handBackDialogShow = ref<boolean>(false) // 退回签约弹窗
    const rejectDialogShow = ref<boolean>(false)// 驳回院系弹窗
    const pattern = ref<string>("0"); // 签约方式
    const signMode = ref<number>(0);
    const year = ref<string>("");
    const handBackConfig = reactive({
      account_type: '',
      reason: ''
    })
    const rejectConfig = reactive({
      value: ''
    })
    const SignAuditStatus = ref<string>('') // 校级审核状态
    const SignAuditMode = ref<boolean>(false); // 校级审核类别
    // 签约数据
    const requestStateData = reactive({
      sign_mode: '',
      agreement_state: '',
      stateby: '',
      audit_mode: false,
      request_state_list: Array<requestStateitem>(),
      department_field_list: Array<fileditem>(),
    })
    const signParams = reactive({
      talent_info: {},
      department_info: {},
      profile_address: {},
    });
    const checkinfo = reactive({
      passed: false,
      remark: { register_number_remark: "", department_name_remark: "" },
      reason: "",
      employer_type: "",
    });
    const gollegeinfo = reactive({
      page: "signCheck",
      time: '',
    });
    const hiddeTalentForm = ref(false)
    const studentData = reactive({
      audit_status:null,
      studentid:'',
    })
    const extractHash = (url) => {
      const hashIndex = url.indexOf('#');
      if (hashIndex !== -1) {
        return url.slice(hashIndex + 1);
      }
      return '';
    }
    const getRecordidFromUrl = () => {
      let url = extractHash(window.location.href);
      let querys = url.substring(url.indexOf("?") + 1).split("&");
      let result: any[] = [];
      for (let i = 0; i < querys.length; i++) {
        let temp = querys[i].split("=");
        console.log(temp);
        if (temp.length < 2) {
          result[temp[0]] = "";
        } else {
          result[temp[0]] = temp[1];
        }
      }

      recordid.value = result["recordid"];
      let tempToken = result["token"];
      let tempAccountid = result["accountid"];
      let usertype = result["usertype"];
      userRole = result["userRole"];
      SignAuditStatus.value = result["audit_status"]
      gollegeinfo.time = result["t"]
      //token accountid usertype 小程序传参
      if (tempToken) {
        StorgeUtils.set('token', tempToken);
      }
      if (tempAccountid) {
        StorgeUtils.set("accountid", tempAccountid);
      }
      if (usertype) {
        StorgeUtils.set("usertype", usertype);
      }
    };
    getRecordidFromUrl();
    //使用表单名称载入对应表单
    const LoadExtForm = (formname: string, formschema: any) => {
      const form = getForm(formname);
      debugger
      if (form) {
        formschema = formschema.push(form);
      }
    };
    //调用接口加载表单 根据类型就业类别加载
    const loadExtFormByHttp = (employmentType: string) => {
      Api.collegeSignInfoForm(year.value, employmentType).then((res) => {
        if (res.status) {
          const formName = `sign_${res.data.employment_type}`;
          const index = signForm.findIndex((item) => item.name == formName);
          let signSelfList = res.data.self_field_list;
          //debugger;
          if (index) {
            for (let item of res.data.field_list) {
              let location = signForm[index].data.length - 1;
              signForm[index].data.splice(location, 0, item);
            }
            //signForm[index].data.unshift()
            //console.log(signForm[index].data)
            if (signSelfList && signSelfList.length > 0) {
              for (let item of res.signSelfList) {
                let location = signForm[index].data.length - 1;
                signForm[index].data.splice(location, 0, item);
              }
              selfDepartmentFieldList.value = JSON.stringify(signSelfList);
            }
          }
          showSign.value = true;
        }
        //console.log(res);
        console.log(signForm);
      });
    };

    //卸载表单名称以后加载的表单
    const UnloadExtFormAfter = (formname: string, formschema: any) => {
      const index = formschema.findIndex((item) => item.name == formname);
      console.log(index);
      if (index > -1) {
        formschema.splice(index, 1);
      }
    };
    //清空签约表单值  在切换就业类别时
    const emptySignData = (except: Array<string> = []) => {
      dataSign.forEach((value, key) => {
        if (!except.includes(key)) {
          dataSign.set(key, undefined);
        }
      });
    };
    //选择就业类别
    provide("signForm", signForm);
    provide("signModePopup", signModePopup);
    provide("LoadExtFrom", LoadExtForm);
    provide("UnloadExtFormAfter", UnloadExtFormAfter);
    provide("emptySignData", emptySignData);
    provide("loadExtFormByHttp", loadExtFormByHttp);
    provide("signMode", signMode);
    Api.getTeacherInfo().then(res => {
      console.log('获取教师信息', res)
      collegestatus.value = res.data.account_level
    }).catch(err => { console.error(err) })
    //获取签约表单相关信息
    Api.collegeSignUp(recordid.value).then((res) => {
      console.log("审核表单", res);
      StorgeUtils.set("year", res.data.year);
      StorgeUtils.set("collegeid", res.data.collegeid);
      StorgeUtils.set("facultyid", res.data.facultyid);
      requestStateData.sign_mode = res.data.sign_mode
      requestStateData.agreement_state = res.data.request_state?.agreement_state
      requestStateData.stateby = res.data.request_state?.stateby
      requestStateData.audit_mode = res.data.audit_mode
      requestStateData.request_state_list = res.data.request_state_list
      requestStateData.department_field_list = res.data.department_field?.field_list
      SignAuditMode.value = res.data.audit_mode
      signMode.value = res.data.sign_mode;
      const { _statusStr,_editBtnShow, _auditBtnShow, _resetBtnShow, _handBackBtnShow, _backFacultyBtnShow } = getDisplayStatus(res)
      isShowNOPass.value = _statusStr
      editBtn.value = _editBtnShow
      pass.value = _auditBtnShow
      isReset.value = _resetBtnShow
      handBack.value = _handBackBtnShow
      backFaculty.value = _backFacultyBtnShow
      studentData.audit_status = res.data.student_data.audit_status
      studentData.studentid = res.data.student_data.studentid
      // 生源表单
      let fieldData = res.data.talent_field.field_data;
      let selfFieldData = res.data.talent_field.self_field_data;
      let fieldList = res.data.talent_field.field_list;
      let selfFileList = res.data.talent_field.self_field_list;
      pattern.value = res.data.sign_mode;
      for (let key in fieldData) {
        //console.log(key,obj[key]);
        dataTalent.set(key, fieldData[key]);
      }
      for (let key in selfFieldData) {
        //console.log(key,obj[key]);
        dataTalent.set(key, selfFieldData[key]);
      }
      LoadExtForm("student_source", schema);
      showTalent.value = true;

      if (fieldList.length > 0) {
        for (let item of fieldList) {
          schema[0].data.push(item);
        }
      }

      if (selfFileList.length > 0) {
        for (let item of selfFileList) {
          schema[0].data.push(item);
        }
      }

      for (let item of schema[0].data) {
        item.is_disable = true;
      }
      store.commit("fieldRemarkChange",res.data?.department_field?.required_field_remark);
      // 签约表单
      let signData = res.data.department_field?.field_data;
      let signFieldData = res.data.department_field?.field_list;
      let signSelfList = res.data.department_field?.self_field_list;
      let signSelfListData = res.data.department_field?.self_field_data;
      let archivesFieldData = res.data.archives_field?.field_data;
      let archivesFieldList = res.data.archives_field?.field_list;
      const formName = `sign_${res.data.department_field.employment_type}`;
      const formStatusName = `sign_status_${res.data.department_field.employment_status || res.data.department_field.field_data.employment_status}`
      //处理表单的值 值处理完后再加载表单 否则值会未绑定
      for (let key in signData) {
        //console.log(key,obj[key]);
        dataSign.set(key, signData[key]);
      }
      for (let key in signSelfListData) {
        dataSign.set(key, signSelfListData[key]);
      }
      for (let key in archivesFieldData) {
        dataSign.set(key, archivesFieldData[key]);
      }
      // LoadExtForm("student_source", schema);
      LoadExtForm("sign_base", signForm);
      LoadExtForm(formStatusName, signForm);
      LoadExtForm(formName, signForm);
      if (res.data.additional_info&&res.data.sign_mode == 1) {
        console.log(123, res, signForm)
        let sign_01List = signForm.filter(v => v.name === 'sign_01')
        if (sign_01List.length) {
          sign_01List[0].data.push({
            control_type: "textbox",
            field: "additionalInfoResponsibility",
            is_disable:true,
            is_talent_view:true,
            lockvalue:res.data.additional_info.responsibility,
            name:"违约责任",
            prompt_msg:"",
            required:false,
            type:'textarea'
          })
        }
      }
      const index = signForm.findIndex((item) => item.name == formName);
      // console.log(index);
      if (index) {
        let signFormData = signForm[index].data
        console.log(signFieldData);
        for (let item of signFieldData) {
          let location = signForm[index].data.length - 1;
          signForm[index].data.splice(location, 0, item);
        }
        // console.log(signForm[index]);
        if (signSelfList && signSelfList.length > 0) {
          for (let item of signSelfList) {
            let location = signForm[index].data.length - 1;
            signForm[index].data.splice(location, 0, item);
          }
        }
        if(archivesFieldList && archivesFieldList.length > 0 && Object.values(archivesFieldData).join('')){
          for (let item of archivesFieldList) {
            let location = signForm[index].data.length - 1;
            signForm[index].data.splice(location, 0, item);
          }
        }
        signFormData.push(signFormData.splice(signFormData.findIndex(v=>v.field==='business_license'),1).pop() as any)
        signFormData.push(signFormData.splice(signFormData.findIndex(v=>v.field==='protocol_photos'),1).pop() as any)
      }
      signForm.forEach((form) => {
        form.data.forEach((item) => {
          item.is_disable = true;
        });
      });

      // console.log(dataSign, 22222);
      //ata = res.talent_field.field_data;
    });

    const GetData = (form: any, data: any) => {
      //过滤不在表单堆栈中的值

      let fields = new Map<string, undefined>();
      //生成表单字段表
      //console.log(form);
      form.forEach((form) => {
        form.data.forEach((item) => {
          fields.set(item.field, undefined);
        });
      });
      console.log(fields);
      //检查data是否在表单字段中
      let retdata = new Map<string, any>();
      data.forEach((value, key) => {
        if (fields.has(key)) retdata.set(key, value);
      });

      console.log(retdata);
      return retdata;
    };
    // 重新审核
    const againcheck = () => {
      pass.value = true;
      isReset.value = false;
      isShowNOPass.value = "";
      dialogVisible.value = false;
      // if(signMode.value == 1){
      //   handBack.value = true
      // }
      // if(SignAuditMode.value){
      //   backFaculty.value = true
      // }
    };
    // 跳转修改
    const goamend = () => {
      router.push({
        path: "/collegeAmendSign",
      });
    };
    // 格式化表单处理
    const formatSignParams = () => {
      let talentInfo: Map<string, any> = GetData(schema, dataTalent);
      let formData: Map<string, any> = GetData(signForm, dataSign);
      console.log(talentInfo, formData);

      talentInfo.forEach((value, key) => {
        signParams.talent_info[key] = value;
      });
      formData.forEach((value, key) => {
        signParams.department_info[key] = value;
      });
    };
    // 规则校验
    const validationrules = () => {
      formatSignParams();
      Api.ruleRerification(signParams).then((res) => {
        console.log(res.data.Item2);
        if (res.data.Item1) {
          ElMessage({
            message: "已通过规则校验",
            type: "warning",
          });
        } else {
          errorshow.value = true;
          checkerror.value = res.data.Item2;
          console.log(checkerror.value);
        }
      });
    };
    // 审核通过
    const approve = async () => {
      checkinfo.passed = true;
      Api.collegeCheckSign(recordid.value, checkinfo).then((res) => {
        // console.log(res);
        if (res.status) {
          ElMessage({
            message: "审核成功！",
            type: "success",
            offset: 50,
          });
          let obj = JSON.parse(JSON.stringify(gollegeinfo));
          window.parent.postMessage(obj, "*");
        }
      });
    };
    // 审核不通过
    const disagree = async () => {
      checkinfo.passed = false;
      console.log(checkinfo);
      Api.collegeCheckSign(recordid.value, checkinfo).then((res) => {
        // console.log(res);
        if (res.status) {
          ElMessage({
            message: "审核成功！",
            type: "success",
            offset: 50,
          });
          disagreedialog.value = false;
          let obj = JSON.parse(JSON.stringify(gollegeinfo));
          window.parent.postMessage(obj, "*");
        }
      });
    };
    // 获取展示状态
    const getDisplayStatus = (res: any) => {
      let _statusStr = '' // 状态展示文本
      let _editBtnShow = false // 修改按钮展示
      let _auditBtnShow = false // 审核按钮展示
      let _resetBtnShow = false // 重新审核按钮展示
      let _handBackBtnShow = false // 退回按钮展示
      let _backFacultyBtnShow = false // 退回院系按钮展示
      const { data: { request_state: { current_state, remark }, audit_mode, sign_mode } } = res
      if(current_state == 0){ // 未提交
      } else if (current_state == 2) { // 已通过
        if(userRole == 'college'){
          _resetBtnShow = true
          if(sign_mode!==1){
            _editBtnShow = true
          }
        }
      } else if (current_state == 3) { // 未通过
        _statusStr = remark
      } else if (current_state == 6) { // 撤回中
      } else if (current_state == 7) { // 已撤回
        _statusStr = remark
      } else if (current_state == 8) { // 解约中
      } else if (current_state == 9) { // 待企业确认
      } else if (current_state == 10) { // 待学生确认
      } else if (current_state == 11) { // 待院系审核
        if(userRole == 'faculty'){
          _auditBtnShow = true
          if(sign_mode==1){
            _handBackBtnShow = true
          }else{
            _editBtnShow = true
          }
        }
      } else if (current_state == 12) { // 待学校审核
         if(userRole == 'college'){
           _auditBtnShow = true
           if(audit_mode){
             _backFacultyBtnShow = true
            }
            if(sign_mode==1){
              _handBackBtnShow = true
            }else{
            _editBtnShow = true
          }
        }else if(userRole == 'faculty' && audit_mode && sign_mode!==1){
            _editBtnShow = true
        }
      } else if (current_state == 13) { // 已解约
      } else if (current_state == 14) { // 人才拒绝
        _statusStr = remark
      } else if (current_state == 15) { // 企业拒绝
        _statusStr = remark
      } else if (current_state == 16) { // 签约中退回-待学生修改
        _statusStr = remark
      } else if (current_state == 17) { // 签约中退回-待企业修改
        _statusStr = remark
      } else if (current_state == 18) { // 学生创建未提交
      } else if (current_state == 19) { // 退回给院系
        _statusStr = remark
        if(userRole == 'faculty'){
          _auditBtnShow = true
          if(sign_mode==1){
            _handBackBtnShow = true
          }else{
            _editBtnShow = true
          }
        }
      }
      return {
        _statusStr,
        _editBtnShow,
        _auditBtnShow,
        _resetBtnShow,
        _handBackBtnShow,
        _backFacultyBtnShow
      }
    }
    const handBackSign = () => {
      console.log(123, handBackConfig)
      if (!handBackConfig.account_type) {
        ElMessage({
          message: '请选择退回单位',
          type: 'warning',
          offset: 50
        })
        return
      }
      if (!handBackConfig.reason) {
        ElMessage({
          message: '请输入退回原因',
          type: 'warning',
          offset: 50
        })
        return
      }
      Api.handBackSign(recordid.value, handBackConfig).then(res => {
        if (res.status) {
          ElMessage({
            message: "已退回",
            type: "success",
            offset: 50,
          });
          handBackDialogShow.value = false;
          let obj = JSON.parse(JSON.stringify(gollegeinfo));
          window.parent.postMessage(obj, "*");
        }
      })
    }
    const rejectSign = () => {
      console.log(123, rejectConfig)
      if (!rejectConfig.value) {
        ElMessage({
          message: '请输入驳回原因',
          type: 'warning',
          offset: 50
        })
        return
      }
      Api.rejectSign(recordid.value, rejectConfig).then(res => {
        console.log(5566,res)
        if (res.status) {
          ElMessage({
            message: "驳回成功！",
            type: "success",
            offset: 50,
          });
          rejectDialogShow.value = false;
          let obj = JSON.parse(JSON.stringify(gollegeinfo));
          window.parent.postMessage(obj, "*");
        }else{
          ElMessage({
          message: '驳回失败',
          type: 'warning',
          offset: 50
        })
        }
      })
    }
    const toAuditStudent= ()=>{
      let obj = JSON.parse(JSON.stringify(gollegeinfo));
      obj.type = 'toAuditStudent'
      obj.studentid = studentData.studentid
      window.parent.postMessage(obj, "*");
    }
    const toEdit = ()=>{
      router.push({ name: '/collegeAmendSign', query:{...route.query,back:1} });
    }
    return {
      schema,
      signForm,
      showTalent,
      showSign,
      dataTalent,
      dataSign,
      GetData,
      againcheck,
      approve,
      validationrules,
      goamend,
      disagree,
      pattern,
      signModePopup,
      signTipsPopup,
      checkerror,
      isShowNOPass,
      isReset,
      handBack,
      backFaculty,
      errorshow,
      dialogVisible,
      disagreedialog,
      handBackDialogShow,
      editBtn,
      pass,
      checkinfo,
      requestStateData,
      handBackSign,
      handBackConfig,
      SignAuditStatus,
      SignAuditMode,
      rejectConfig,
      rejectDialogShow,
      rejectSign,
      hiddeTalentForm,
      studentData,
      toAuditStudent,
      toEdit,
    };
  },
});
</script>
<style scoped>
.btn-wrap {
  height: 60px;
  margin: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.err-text {
  text-align: center;
  margin: 10px 0;
  font-size: 14px;
  font-family: 微软雅黑;
  color: black;
}

.sign-select .sign-type img {
  width: 60px;
  height: 60px;
}

.sign-select .sign-type div {
  font-size: 12px;
  color: #cccccc;
}

.statewords {
  color: red;
  font-size: 16px;
  font-family: 微软雅黑;
}

.el-overlay-dialog {
  top: 250px !important;
}
</style>