const token = 'a081c6cdf1c34e3b8007b20a80526a5a'
const accountid = '6256232a984ed23aadd3644d';
const clientid='594a350e50dff3ced1f098e0'
const clientidCollege = '59c0e143555818d2af8e4a81';
const clientidWX = '5d72147354e6913fe6452a5e'



let ApiUrl =''; //API地址
let AgentApiUrl =''; //代理地址
let departmentUrl ='';
let dataApi ='';        // http请求前缀
let cdApi ='';        // http请求前缀
let origin =''; //iframe 接收参数的源网站
let originCollege=''; //iframe 接收参数的源网站
let form ='';
let recommendApi=''

//测试api
ApiUrl = "https://webapi.test.goworkla.cn"; //API地址
AgentApiUrl = 'https://agent.test.goworkla.cn'; //代理地址
departmentUrl = "http://agreement.test.goworkla.cn/#/state/";
dataApi = 'https://dataapi.test.goworkla.cn';        // http请求前缀
cdApi = 'http://cdapi.test.goworkla.cn';        // http请求前缀
origin = 'https://talent2.test.goworkla.cn'; //iframe 接收参数的源网站
originCollege = 'https://college2.test.goworkla.cn'; //iframe 接收参数的源网站
form = 'https://form.test.goworkla.cn/#/';
recommendApi='https://recommend.test.goworkla.cn';


//正式api
// ApiUrl = "https://webapinet6.goworkla.cn";//API地址
// AgentApiUrl = "https://agentnew.goworkla.cn";
// dataApi = 'https://dataapi.goworkla.cn';
// cdApi = 'https://cdapi.goworkla.cn';
// departmentUrl = "https://agreement.goworkla.cn/#/state/";
// origin = 'https://talent.goworkla.cn'; //iframe 接收参数的源网站
// originCollege = 'https://college.goworkla.cn'; //iframe 接收参数的源网站
// form = 'https://form.goworkla.cn/#/';
// recommendApi='https://webapiv2.goworkla.cn';



export default{
    ApiUrl:ApiUrl,
    AgentApiUrl:AgentApiUrl,
    departmentUrl:departmentUrl,
    dataApi:dataApi,
    cdApi:cdApi,
    clientid:clientid,
    token:token,
    accountid:accountid,
    clientidCollege:clientidCollege,
    origin:origin,
    originCollege:originCollege,
    form:form,
    clientidWX:clientidWX,
    recommendApi
}