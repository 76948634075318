<template>
  <div v-if="(item && seen)" v-show="item.field != 'graduation'" class="form-components" :class="{'el-form-item is-error':store.state.errorFileList.includes(item.field)}">
    <div class="label">
      <el-tooltip
        v-if="item.remark||stateFieldRemark[item.field]"
        :content="item.remark||stateFieldRemark[item.field]"
        popper-class="remark-popper"
        placement="top-start"
        trigger="click"
      >
        <img src="https://images.goworkla.cn/wxAppletImg/EnergyValue_result_about.png" class="label-remark"/>
      </el-tooltip>
      <span class="remind-ball" v-if="item.required">*</span>{{ item.name }}
    </div>
    <div class="content">
      <template v-if="list">
     <el-select v-model="datavalue" :disabled="item.is_disable" :placeholder="item.prompt_msg||`请选择${item.name}`" :allow-create="item.allow_create" :filterable="item.allow_create||item.filterable"
        @change="change">
        <el-option v-for="item in list" :key="item.code" :label="item.text" :value="item.code" :disabled="item.disabled"/>
      </el-select>
    </template>
    </div>
    <!-- <div>
    <el-input
      v-model="searchkeyword"
      placeholder="Search"
      @input="filterOptions"
    />
    <el-select
      v-model="selectedOption"
      placeholder="Select"
      size="large"
      style="width: 240px"
      @visible-change="handleVisibleChange"
    >
      <el-option
        v-for="option in displayedOptions"
        :key="option.value"
        :label="option.label"
        :value="option.value"
      />
    </el-select>
  </div> -->
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, inject, onMounted, onUpdated, PropType, provide, ref, toRefs, watch } from "vue";
import { selectitemdata } from "../form/dataform";
import { dict, dictitem } from "../form/dictProvider";
import { useStore } from "vuex";
import { key } from "./../state/store";
import  {StorgeUtils } from "../utils/StorageUtils";
export default defineComponent({
  name: "cfSelect",
  props: {
    item: {
      type: Object as PropType<selectitemdata>,
      default: null,
    },
    value: [String,Number],
  },
  emits: ["input", "valuechange"],
  setup(props, context) {
    const store = useStore(key);
    let list = ref<dictitem[]>([]);
    let item = toRefs(props).item.value;
    let datavalue = ref(props.value);
    //console.log(datavalue);
    //console.log(item.field,datavalue.value);
    const dictprovide = inject<dict>("dict");
    const signForm = inject("signForm");
    const LoadExtForm: any = inject("LoadExtFrom");
    const UnloadExtFormAfter: any = inject("UnloadExtFormAfter");
    const loadExtFormByHttp: any = inject("loadExtFormByHttp");
    const signModePopup: any = inject("signModePopup");
    const emptySignData: any = inject("emptySignData");
    const signMode: any = inject("signMode");
    const recordid: any = inject("recordid");
    const selectSignMode: any = inject("selectSignMode");

    watch(() => store.state.isTrouble, (val, oldVal) => {
      if (!val && item.field == 'filing_card_poor_family') {
        datavalue.value = '否'
        context.emit("valuechange", datavalue.value);
      }
    })
    watch(() => store.state.education, (val, oldVal) => {
      if (val && item.field == 'source_major') {
        loadDictData()
      }
    })
    watch(() => store.state.studyAbroadCollege, (val, oldVal) => {
      if (val?.code&&val?.level) {// 有可能是修改进来的，尽可能排除是创建的选项
        if(['study_abroad_name','study_abroad_fname'].includes(item.field)){
          datavalue.value = val.code
          context.emit("valuechange", datavalue.value);
        }else if(item.field === 'study_abroad_country'){
          datavalue.value = val.relation_code
          context.emit("valuechange", datavalue.value);
        }
      }
    },{deep:true})
    // 签约方式选项与值填充
    watch(()=>({signMode:signMode,signModeOptions:store.state.signModeOptions,employmentType:store.state.employment_type}),({signMode,signModeOptions,employmentType},oldVal)=>{
      // 不是签约方式字段跳过
      if(item.field !== 'sign_mode') return
      // 设置选项
      list.value = signModeOptions
      const usertype = StorgeUtils.get('usertype');
      if(usertype === 'college'){
        datavalue.value = 0
        context.emit("valuechange", datavalue.value);
        return
      }
      // 不是签就业协议形式就业统一设置签约方式是备案
      if(employmentType !== '01'){
        datavalue.value = 0
        context.emit("valuechange", datavalue.value);
        return
      }
      // 被禁用了就获取原有值
      if(item.is_disable){
        datavalue.value = signMode.value
        context.emit("valuechange", datavalue.value);
        return
      }
      console.log(666,signMode,signModeOptions,employmentType,oldVal)
      // 
      if(oldVal?.employmentType!=='01'){
        datavalue.value = undefined
        selectSignMode(datavalue.value)
        context.emit("valuechange", datavalue.value);
        
      }
    },{deep:true,immediate:true})
    const loadDictData = () => {
      if (dictprovide) {
        if (item) {
          if(item.field === 'sign_mode') return
          //console.log(item);
          let source = item.dict_type;
          let listRes = dictprovide.GetDict(source);
          //console.log(`${item.field}`,listRes);
          listRes.then((res) => {
            //console.log(res);
            let listtemp = res;
            if(item.field === 'study_abroad_fname'){
              listtemp = res.map(v=>({...v,text: v.relation_desc!}))
            }
            list.value = listtemp;
            //先处理强制值
            if (item.lockvalues) {
              list.value = dictprovide.Lock(listtemp, item.lockvalues);
              if (!list.value.length){
                list.value = list.value.concat(item.lockvalues.map(v=>({text:v, code: v})))
              }
            }
            //再处理排除值
            else if (item.exceptvalues) {
              list.value = dictprovide.Except(listtemp, item.exceptvalues);
            }
            //有编码字段下拉选择处理
            if (item.code_options) {
              let optionList = item.code_options;
              let x: dictitem[] = [];
              for (let optionItem of optionList) {
                //console.log(item);
                x.push({ text: optionItem.text, code: optionItem.code });
                //list.value.push({text:value,code:value})
              }
              list.value = x;
            }
            //自定义字段下拉选择处理
            if (item.options) {
              let optionList = item.options;
              let x: dictitem[] = [];
              for (let value of optionList) {
                //console.log(item);
                x.push({ text: value, code: value });
                //list.value.push({text:value,code:value})
              }
              list.value = x;
            }
            //计算是否值包含在选项内，否则清空该值
            if(list.value?.length){
              if(list.value.length===1 && datavalue.value!==list.value[0].code) {
                datavalue.value = list.value[0].code
                context.emit("valuechange", datavalue.value);
              }else if(list.value.length>1 && list.value.every(v=>v.code!==datavalue.value)) {
                datavalue.value = undefined;
                context.emit("valuechange", datavalue.value);
              }
            }
            //学历专业关联
            if (item.field == "education") {
              store.commit("educationChange", datavalue.value);
            }
            if (item.field == "training_mode") {
              if (datavalue.value == "02" || datavalue.value == "03") {
                store.commit("isDirectionalChange", true);
              } else {
                store.commit("isDirectionalChange", false);
              }
            }
            // 困难生关联
            if (item.field == "hardship_student_type") {
              if (datavalue.value == "02" || datavalue.value == "03" || datavalue.value == "06" || datavalue.value == "07") {
                store.commit("isTroubleChange", true);
              } else {
                store.commit("isTroubleChange", false);
              }
            }
            if (item.field == "employment_type" && datavalue.value&&!item.is_disable) {
              const employment_type = store.state.employment_type;
              //切换就业类别后默认签约方式为备案
              if (employment_type) {
                const unloadFromName = `sign_${employment_type}`;
                UnloadExtFormAfter(unloadFromName, signForm);
              }
              // if (datavalue.value == "01"&&!recordid.value) {
              //   signModePopup.value = true;
              // }
              if(datavalue.value !== "01"){
                const loadFormName = `sign_${datavalue.value}`;
                LoadExtForm(loadFormName, signForm);
                loadExtFormByHttp(datavalue.value);
              }
              store.commit("employmentTypeChange", datavalue.value);
            }
          });
        }
      }
    };
    if(item.field == 'source_major'){
      if(store.state.education){
        loadDictData();
      }
    }else {
      loadDictData();
    }
    const change = () => {
      //切换就业类别后清空所有的值
      if (item.field == "employment_type") {
        emptySignData(["employment_status"]);
      }else if(item.field == "employment_status"){
        emptySignData();
      } else if (item.field === 'sign_mode'){
        selectSignMode(datavalue.value)
        context.emit("valuechange", datavalue.value);
      }
      context.emit("input", datavalue.value);
      // 留学院校关联
      if(['study_abroad_name','study_abroad_fname'].includes(item.field)){
       let item = list.value.find(v=>v.code === datavalue.value)
       if(item){
         store.commit("studyAbroadCollegeChange", item);
       }
      }
      //学历专业关联
      if (item.field == "education") {
        store.commit("educationChange", datavalue.value);
        //console.log(store.state.education)
      }
      // 定向委培关联
      if (item.field == "training_mode") {
        if (datavalue.value == "02" || datavalue.value == "03") {
          store.commit("isDirectionalChange", true);
          //console.log(store.state.isDirectional);
          //console.log('定向')
        } else {
          store.commit("isDirectionalChange", false);
        }
      }
      // 困难生关联
      if (item.field == "hardship_student_type") {
        if (datavalue.value == "02" || datavalue.value == "03" || datavalue.value == "06" || datavalue.value == "07") {
          store.commit("isTroubleChange", true);
        } else {
          store.commit("isTroubleChange", false);
        }
      }

      //就业类别切换
      if (item.field == "employment_type") {
        const employment_type = store.state.employment_type;
        //切换就业类别后默认签约方式为备案
        signMode.value = 0;
        store.commit("signModeChange",0);
        //console.log(employment_type);
        //debugger;
        if (employment_type) {
          const unloadFromName = `sign_${employment_type}`;
          UnloadExtFormAfter(unloadFromName, signForm);
          //console.log(signForm);
        }
        // if (datavalue.value == "01") {
        //   signModePopup.value = true;
        // }
        const usertype = StorgeUtils.get('usertype');
        if(datavalue.value !== "01" || usertype === 'college'){
          const loadFormName = `sign_${datavalue.value}`;
          LoadExtForm(loadFormName, signForm);
          loadExtFormByHttp(datavalue.value);
        }
        store.commit("employmentTypeChange", datavalue.value);
      }
      //就业状况切换
      if (item.field == "employment_status") {
        console.log(123123)
        const employment_status = store.state.employment_status;
        const employment_type = store.state.employment_type;
        //切换就业类别后默认签约方式为备案
        signMode.value = 0;
        store.commit("signModeChange",0);
        //console.log(employment_type);
        //debugger;
        if (employment_status) {
          const unloadFromName = `sign_status_${employment_status}`;
          UnloadExtFormAfter(unloadFromName, signForm);
          //console.log(signForm);
        }
        if (employment_type) {
          const unloadFromName = `sign_${employment_type}`;
          UnloadExtFormAfter(unloadFromName, signForm);
          //console.log(signForm);
        }
        const loadFormName = `sign_status_${datavalue.value}`;
        LoadExtForm(loadFormName, signForm);
        store.commit("employmentStatusChange", datavalue.value);
      }
    };

    //console.log(list);

    /**
     * 
     * 
     * 
     * 
     * 
     */
     
    return { list, datavalue, change, store, loadDictData};
  },
  computed: {
    seen: function () {
      let result = true;
      if (this.item.field == 'filing_card_poor_family') {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        result = this.store.state.isTrouble;
      } else if (this.item.field === 'sign_mode'){
        const usertype = StorgeUtils.get('usertype');
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        result = this.store.state.employment_type == '01' && usertype !== 'college'
      }
      return result;
    },
    stateFieldRemark:function(){
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return this.store.state.fieldRemark
    }
  }
});
</script>
