import { createApp } from 'vue'
import { store, key } from './state/store'
import App from './App.vue'
import router from './router/router'
import ElementPlus from 'element-plus';
//import Vant from 'vant';
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import 'element-plus/dist/index.css';
//import 'vant/lib/index.css';
import './public/public.css';

const app = createApp(App);
app.use(ElementPlus, {
    locale: zhCn,
});
app.use(store, key);
//app.use(Vant);
app.use(router).mount('#app');

