import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_image_viewer = _resolveComponent("el-image-viewer")!

  return (_openBlock(), _createBlock(_component_el_image_viewer, {
    key: _ctx.imageIndex,
    ref: "imageViewerRef",
    "url-list": _ctx.imageList,
    "initial-index": _ctx.imageIndex,
    onClose: _ctx.close
  }, null, 8, ["url-list", "initial-index", "onClose"]))
}