<template>
  <div class="g-container">
    <div v-for="(itemconfig, index) in formconfig" :key="index">
       <component v-for="item in itemconfig.data"
          :key="item.field + itemconfig.name"
          :is="GetControlType(item.control_type, item)"
          :item="item"
          :value="GetValue(item.field)"
          @input="SetValue(item.field, $event)"
          @valuechange="SetValue( item.field, $event)"
        ></component>
        <!-- 占位标签，防止上一行只有一个字段时整行展示 -->
        <div class="form-components" style="margin: 0;"></div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, provide, ref} from "vue";
import { formschema,FormItem } from "../form/dataform";
import _textarea from "./cfMutiline.vue";
import dropdown from "./cfSelect.vue";
import dropdownv2 from "./cfSelectv2.vue";
import number from "./cfNumber.vue";
import textbox from "./cfText.vue";
import imageupload from "./cfImage.vue";
import datetime from "./cfDate.vue";
import areaselect from "./cfAreaSelect.vue";
import singleimageupload from "./cfImageshow.vue";
import { dict } from "../form/dictProvider";

export default defineComponent({
  name: "dForm",
  components: {
    _textarea,
    dropdown,
    dropdownv2,
    number,
    textbox,
    imageupload,
    datetime,
    areaselect,
    singleimageupload,
  },
  props: {
    formconfig: {
      type: Object as PropType<Array<formschema>>,
      default: null,
    },
    data: {
      type: Object as PropType<Map<string, any>>,
      default: null,
    },
  },
  emits: ["input"],
  setup(props) {
    console.log('组件接收数据',props.formconfig,props.data)
    const data = ref(props.data);
    //console.log(data, typeof data.value);
    const forms = ref<FormItem[]>([]);
    for(let item of props.formconfig){
      forms.value = [...item.data]
    }
    const GetControlType = (control_type:string,item:any)=>{
      let controlType = control_type
      if(controlType === 'dropdownCode'){
        controlType = 'dropdown'
      }
      if(controlType === 'textbox' && item.field_type==='int'){
        controlType = 'number'
      }
      return controlType
    }
    //console.log(forms.value);
    const GetValue = ( itemname: string) => {
      const v = data.value.get(itemname);
      //console.log(itemname,v);
      if (v) {
        return v;
      }

      return null;
    };
    const SetValue = ( itemname: string, itemevent: any) => {
      //console.log(itemevent);
      data.value.set(itemname, itemevent);
      //console.log(data);
    };
    
    //实例化dictprovider
    const dictins = new dict();
    provide<dict>("dict", dictins);

    return { GetControlType, GetValue, SetValue,forms };
  },
});
</script>
<style>
.g-container>div{
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0  auto;
}
@media screen and (max-width: 750px){
  
}
</style>
