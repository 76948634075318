import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b5c1f706"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "btn-wrap"
}
const _hoisted_2 = {
  key: 0,
  class: "btn-wrap"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_step = _resolveComponent("el-step")!
  const _component_el_steps = _resolveComponent("el-steps")!
  const _component_dformVue = _resolveComponent("dformVue")!
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_steps, {
      active: _ctx.step,
      "align-center": ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_step, { title: "生源信息" }),
        _createVNode(_component_el_step, { title: "签约信息" })
      ]),
      _: 1
    }, 8, ["active"]),
    _withDirectives(_createElementVNode("div", null, [
      (_ctx.showTalent)
        ? (_openBlock(), _createBlock(_component_dformVue, {
            key: 0,
            formconfig: _ctx.schema,
            data: _ctx.dataTalent
          }, null, 8, ["formconfig", "data"]))
        : _createCommentVNode("", true),
      (_ctx.showTalent)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_el_button, {
              type: "primary",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.nextPage()))
            }, {
              default: _withCtx(() => [
                _createTextVNode("下一页")
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true)
    ], 512), [
      [_vShow, _ctx.step == 0]
    ]),
    _withDirectives(_createElementVNode("div", null, [
      _createVNode(_component_dformVue, {
        class: "step2",
        formconfig: _ctx.signForm,
        data: _ctx.dataSign
      }, null, 8, ["formconfig", "data"]),
      (_ctx.showSign)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_el_button, {
              type: "primary",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.prevPage()))
            }, {
              default: _withCtx(() => [
                _createTextVNode("上一页")
              ]),
              _: 1
            }),
            _createVNode(_component_el_button, {
              type: "primary",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.submit()))
            }, {
              default: _withCtx(() => [
                _createTextVNode("提交")
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true)
    ], 512), [
      [_vShow, _ctx.step == 1]
    ])
  ], 64))
}