<template>
  
  <div v-if="item" class="form-components multiple" :class="{'el-form-item is-error':store.state.errorFileList.includes(item.field)}">
    <div class="label"><span class="remind-ball" v-if="item.required">*</span>{{ item.name }}</div>
    <div class="content">
      <el-input
        v-model="datavalue"
        :rows="item.row"
        type="textarea"
        :disabled="item.is_disable"
        :placeholder="item.prompt_msg"
         @input="change"
      />
    </div>
  </div>
</template>


<script lang="ts">
import { defineComponent, PropType, ref } from "vue";
import { mutilineitemdata } from "../form/dataform";
import { useStore } from 'vuex'
import { key } from './../state/store' 

export default defineComponent({
  name: "cfMutiline",
  props: {
    item: {
      type: Object as PropType<mutilineitemdata>,
      default: null,
    },
    value: String,
  },
  emits: ["input"],
  setup(props,context) {
    const store = useStore(key);

    const datavalue = ref(props.value);

    const change = () =>{
      context.emit('input',datavalue.value)
    }
    return { datavalue,change,store }
  }

});
</script>
