<template>
  <!-- 占位标签，防止上一行只有一个字段时整行展示 -->
    <!-- 替补末尾空缺 -->
    <div class="form-components" style="margin: 0;"></div>
    <!-- 占用一整行确保下行为第一个 -->
    <div class="form-components" style="margin: 0;flex: unset"></div>
  <div class="form-components" style="flex:unset;align-items:flex-start;" v-if="!((item.field=='business_license'&&signMode!=1)||(item.field=='protocol_photos'&&signMode==1)||(item.field=='protocol_photos'&&signMode==2&&agreementState==-1))">
    <div class="label">
      <el-tooltip
        v-if="item.remark||stateFieldRemark[item.field]"
        :content="item.remark||stateFieldRemark[item.field]"
        popper-class="remark-popper"
        placement="top-start"
        trigger="click"
      >
        <img src="https://images.goworkla.cn/wxAppletImg/EnergyValue_result_about.png" class="label-remark"/>
      </el-tooltip>
      <span class="remind-ball" v-if="item.required">*</span>{{ item.name }}
    </div>
    <div class="content">
      <div class="attachment-upload">  
        <el-upload v-if="!item.is_disable&&imageList.length<4" :action="uploadUrl" class="upload-demo" :before-upload="upLoadFile" :limit='3' :disabled="item.is_disable">
          <el-button  type="primary" :disabled="item.is_disable">上传附件</el-button>
        </el-upload>
        <div class="image-list">
          <div class="image-list-item" v-for="image,index of imageList" :key="image">
            <el-image v-if="usertype!=='wx'" :src="getAbbreviateImg(image)" :fit="fit" @click.stop.prevent="viewImg(index)" @error="pretreatment = false">
            </el-image>
            <el-image v-if="usertype==='wx'" :src="getAbbreviateImg(image)" :fit="fit" :preview-src-list="imageList" :initial-index="index" @error="pretreatment = false">
            </el-image>
            <img v-if="!item.is_disable" src="https://images.goworkla.cn/wxAppletImg/ban.png" class="image-list-item-del" @click="delImg(index)" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    
<script lang="ts">
    import { defineComponent, inject, PropType, reactive, ref } from "vue";
    import { imageitemdata } from "../form/dataform";
    import { ElMessage } from "element-plus";
    import Constants from "@/http/Constant";
    import Api from "@/http/Api";
    import { useStore } from "vuex";
    import { key } from "./../state/store";
    import  {StorgeUtils } from "../utils/StorageUtils";
    export default defineComponent({
        name: "cfImage",
        props: {
            item: {
                type: Object as PropType<imageitemdata>,
                default: null,
            },
            value: Array as PropType<string[]>,
        },
        emits: ["input","valuechange"],
        setup(props,context) {
            const usertype = StorgeUtils.get('usertype');
            const store = useStore(key);
            const datavalue = ref(props.value);
            const fit = ref<string>('contain');
            const imageList = reactive(new Array<string>());
            const imgLsit:Array<string> = [];
            const uploadUrl = `${Constants.ApiUrl}/signup/v3/uploadimage`;
            const signMode: any = inject("signMode");
            const agreementState: any = inject("agreementState");
            if(datavalue.value){
              if(typeof datavalue.value === 'string'){
                datavalue.value = [datavalue.value]
              }
              for(let item of datavalue.value){
                imageList.push(item)
                imgLsit.push(item)
              }
              console.log(imageList)
            }

            const upLoadFile = (file) =>{
              console.log('上传文件格式', file.type)
              if (!file.type.includes('image')) {
                ElMessage.error('请上传jpg或png图片!')
                return false
              } else if(file.size > 2097152){
                ElMessage.error('请上传低于2M的图片!')
                return false
              }
              let reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onloadend = () =>{
                let imageData = reader.result;
                console.log(typeof imageData);
                if(imageData){
                  imageData = replaceStr(imageData);
                  let data = {value:imageData};
                  Api.uploadImage(data).then((res) => {
                    if(res.status){
                      imageList.push(res.data.value);
                      imgLsit.push(res.data.value)
                      //datavalue.value?.push(res.data.value);
                      console.log(imgLsit);
                      context.emit('valuechange',imgLsit)
                    }
                  })
                }
              }
              
              //Api.uploadImage().
              //console.log(file)
              //console.log(1);
              return false;
            }

            const replaceStr = (imageData:any) =>{
              let result = imageData;
              result = result.replace(/^(data:image)\/jpeg;base64,/g, '')
              result = result.replace(/^(data:image)\/png;base64,/g, '')
              return result;
            }
            const delImg = (index:number) =>{
              imageList.splice(index,1);
              imgLsit.splice(index,1);
              context.emit('valuechange',imgLsit)
            }
            const viewImg = (index:number) =>{
              let width = 700
              let height = 850
              let left = (window.screen.availWidth-width)/2
              let top = (window.screen.availHeight-height)/2
              console.log(888777,left,top)
              localStorage.setItem('imageListOnce',JSON.stringify(imageList))
              localStorage.setItem('imageIndexOnce',JSON.stringify(index))
              window.open(`#/imgView?t=${~new Date()}`,'imgView',`width=${width}, height=${height}, left=${left}, top=${top}`)
            }
            const pretreatment = ref(true)
            const getAbbreviateImg = (url:string)=>{
              if(!pretreatment.value)return url
              if(url.includes('?')){
                return url+'&imageView2/0/w/100/h/100'
              }
              return url+'?imageView2/0/w/100/h/100'
            }
            return { 
              usertype,
              datavalue,
              fit,
              imageList, 
              upLoadFile,
              uploadUrl,
              signMode,
              store,
              delImg,
              agreementState,
              viewImg,
              getAbbreviateImg,
              pretreatment
            }
        },
        computed:{
          stateFieldRemark:function(){
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return this.store.state.fieldRemark
          }
        }
    });
</script>
<style scoped>
  .image-list{
    width: 100% !important;
  }

  .image-list-item{
    position: relative;
  }

  .image-list-item-del{
    position: absolute;
    top: -10px;
    right: -3px;
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
</style>
